import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Container } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useStyles } from './BackToStyles';
import { strings } from '~/constants/strings';
import {
  CUSTOMER_RECORD,
  INTERVENTIONS,
  AGENCIES_ADMINISTRATION,
  BUILDING,
  ALL_INTERVENTIONS,
  VISIT_REPORT,
  BILLING,
  QUOTES_MANAGER,
  INVOICES_MANAGER,
  ALL_TODOS,
  MY_ACCOUNT,
  HOME
} from '~/constants/internalLinks';
import { getBillingUrlPath, getInterventionUrlPath } from './shared';

const BackTo = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const locaSplit = location.pathname.split('/').slice(1, 4);
  const customerId = urlParams.get('customerId');
  const buildingId = urlParams.get('buildingId');
  const customerName = urlParams.get('customerName');
  const contactName = urlParams.get('contactName');
  const contactPhone = urlParams.get('contactPhone');
  const customerAddress = urlParams.get('customerAddress');
  const customerCountry = urlParams.get('customerCountry');
  const customerSiret = urlParams.get('customerSiret');
  const gcQuoteReference = urlParams.get('GcQuoteReference');
  const gcInvoiceReference = urlParams.get('GcInvoiceReference');

  const isInvoice = Boolean(urlParams.get('invoiceSource'));
  const isDeposit = Boolean(
    urlParams.get('depositSource') || urlParams.get('depositInvoice')
  );
  const isCreditNote = Boolean(urlParams.get('creditNoteSource'));
  const pathName = locaSplit?.pop();

  let pathNameTrad;
  let pathUrl: string = '/';

  switch (pathName) {
    case CUSTOMER_RECORD.split('/')[1]:
      pathNameTrad = strings.fiche_client;
      break;
    case INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Lignes_d_intervention;
      break;
    case AGENCIES_ADMINISTRATION.split('/')[1]:
      pathNameTrad = strings.administration_des_agences_attila;
      break;
    case BUILDING.split('/')[1]:
      pathNameTrad = strings.historique_du_batiment;
      break;
    case ALL_INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Toutes_les_interventions;
      break;
    case ALL_TODOS.split('/')[1]:
      pathNameTrad = strings.Tous_les_actions_non_terminées;
      break;
    case VISIT_REPORT.split('/')[1]:
      pathNameTrad = strings.rapport_de_visite;
      break;
    case BILLING.split('/')[1]:
      pathNameTrad =
        isInvoice || isDeposit
          ? strings.facture
          : isCreditNote
          ? strings.Avoir
          : strings.Devis;
      break;
    case QUOTES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_devis;
      break;
    case INVOICES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_factures;
      break;
    case MY_ACCOUNT.split('/')[1]:
      pathNameTrad = strings.Mon_compte;
      break;
    default:
      pathNameTrad = '';
  }

  if (history.length === 1 || history.length === 2) {
    if (pathName === CUSTOMER_RECORD.split('/')[1]) {
      pathUrl = customerName ? `${HOME}?customerName=${customerName}` : '/';
    } else if (pathName === INTERVENTIONS.split('/')[1]) {
      pathUrl = getInterventionUrlPath({
        customerId,
        buildingId,
        customerSearch: {
          customerName,
          contactName,
          contactPhone,
          customerAddress,
          customerCountry,
          customerSiret
        }
      });
    } else if (pathName === BUILDING.split('/')[1]) {
      pathUrl = `${INTERVENTIONS}?customerId=${customerId}`;
    } else if (pathName === VISIT_REPORT.split('/')[1]) {
      pathUrl =
        customerId && buildingId
          ? `${INTERVENTIONS}?customerId=${customerId}&buildingId=${buildingId}`
          : '';
    } else if (pathName === BILLING.split('/')[1]) {
      pathUrl = getBillingUrlPath({
        customerId,
        buildingId,
        gcQuoteReference,
        gcInvoiceReference
      });
    } else {
      pathUrl = '/';
    }
  }
  const handleGoBack = () => {
    history.length !== 1 && history.length !== 2
      ? history.goBack()
      : history.push(pathUrl);
  };

  return (
    <>
      <Container maxWidth={false}>
        {pathNameTrad && (
          <div className={classes.backTo}>
            <Button className={classes.link} onClick={handleGoBack}>
              <KeyboardArrowLeftIcon fontSize="large" />
              &nbsp; {pathNameTrad}
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default BackTo;
