import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  backTo: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    marginBottom: '0.5rem'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.gray,
    background: 'none',
    border: 'none',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textTransform: 'inherit',
    lineHeight: 'inherit',
    cursor: 'pointer'
  }
}));
