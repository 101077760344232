/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DisplayPaymentMethodDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface DisplayAccountingEntryDto {
  /** @format int32 */
  id?: number;
  code?: string | null;
  description?: string | null;
  /** @format float */
  taxRate?: number | null;
}

export interface DisplayReconciliationTypeDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
}

export interface DisplayActivitySectorWithActivityDomainsDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  activityDomains?: DisplayActivityDomainDto[] | null;
}

export interface DisplayActivityDomainDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  activitySectorId?: number;
}

export interface CreateActivitySectorDto {
  name?: string | null;
}

export interface CreateActivityDomainDto {
  name?: string | null;
}

export interface UpdateActivitySectorDto {
  name?: string | null;
}

export interface UpdateActivityDomainDto {
  name?: string | null;
}

export interface PagedResultDtoOfDisplayAgencyWithLegalDocumentsDto {
  values?: DisplayAgencyWithLegalDocumentsDto[] | null;
  /** @format int32 */
  count?: number;
}

export type DisplayAgencyWithLegalDocumentsDto = DisplayAgencyDto & {
  ctSt?: DisplayLegalDocumentDto | null;
  blSignature?: DisplayLegalDocumentDto | null;
  annexesTotal?: DisplayLegalDocumentDto | null;
  charteGc?: DisplayLegalDocumentDto | null;
  foreignEmployeesCertificate?: DisplayLegalDocumentDto | null;
  rcd?: DisplayLegalDocumentDto | null;
  ringeval2ndLigne?: DisplayLegalDocumentDto | null;
  kbis?: DisplayLegalDocumentDto | null;
  urssaf?: DisplayLegalDocumentDto | null;
  d1m?: DisplayLegalDocumentDto | null;
  franchiseeProCard?: DisplayLegalDocumentDto | null;
  amianteSs4?: DisplayLegalDocumentDto | null;
  /** @format date-time */
  networkExitDate?: string | null;
  /** @format date-time */
  cfSignatureDate?: string | null;
};

export interface DisplayLegalDocumentDto {
  isApplicable?: boolean;
  files?: DisplayFileDto[] | null;
  /** @format date-time */
  dueDate?: string | null;
}

export interface DisplayFileDto {
  fileId?: string | null;
  fileName?: string | null;
}

export interface DisplayAgencyDto {
  /** @format int32 */
  id?: number;
  comments?: DisplayCommentDto[] | null;
  eligibility?: Eligibility;
  visitDates?: DisplayDateWithMessageDto[] | null;
  name?: string | null;
  siret?: string | null;
  email?: string | null;
  denomination?: string | null;
  isActive?: boolean;
  address?: DisplayAddressDto | null;
  geoLocation?: google.maps.LatLng | google.maps.LatLngLiteral;
  hasAmiante?: boolean;
}

export interface DisplayCommentDto {
  /** @format int32 */
  id?: number;
  message?: string | null;
  /** @format int32 */
  userId?: number;
  /** @format date-time */
  creationDate?: string;
}

export enum Eligibility {
  Eligible = 'Eligible',
  NonEligible = 'NonEligible',
  TemporaryEligible = 'TemporaryEligible'
}

export interface DisplayDateWithMessageDto {
  /** @format date-time */
  date?: string | null;
  message?: string | null;
}

export interface DisplayAddressDto {
  line1?: string | null;
  line2?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface DisplayGeoPointDto {
  /** @format float */
  lng?: number;
  /** @format float */
  lat?: number;
}

export interface PagedResultDtoOfDisplayEligibleAgencyDto {
  values?: DisplayEligibleAgencyDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayEligibleAgencyDto {
  /** @format int32 */
  id?: number;
  comments?: DisplayCommentDto[] | null;
  eligibility?: Eligibility;
  visitDates?: DisplayDateWithMessageDto[] | null;
  name?: string | null;
  siret?: string | null;
  email?: string | null;
  denomination?: string | null;
  isActive?: boolean;
  address?: DisplayAddressDto | null;
  geoLocation?: DisplayGeoPointDto | null;
  hasAmiante?: boolean;
  isRetrievedByZone?: boolean;
}

export interface Comment {
  /** @format int32 */
  id?: number;
  message?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  /** @format int32 */
  userId?: number;
}

export interface CreateCommentDto {
  message?: string | null;
}

export interface UpdateAgencyEligibiltyDto {
  eligibility?: Eligibility;
}

export interface DateWithMessage {
  /** @format date-time */
  date?: string | null;
  message?: string | null;
}

export interface CreateDateWithMessageDto {
  /** @format date-time */
  date?: string | null;
  message?: string | null;
}

export interface LegalDocument {
  /** @format int32 */
  id?: number;
  isApplicable?: boolean | null;
  files?: File[] | null;
  /** @format date-time */
  dueDate?: string | null;
}

export interface File {
  fileId?: string | null;
  fileName?: string | null;
}

export interface Agency {
  /** @format int32 */
  id?: number;
  comments?: Comment[] | null;
  eligibility?: Eligibility;
  visitDates?: DateWithMessage[] | null;
  name?: string | null;
  denomination?: string | null;
  siret?: string | null;
  email?: string | null;
  isActive?: boolean;
  geoLocation?: GeoPoint | null;
  address?: Address | null;
  ctSt?: LegalDocument | null;
  blSignature?: LegalDocument | null;
  annexesTotal?: LegalDocument | null;
  charteGc?: LegalDocument | null;
  foreignEmployeesCertificate?: LegalDocument | null;
  rcd?: LegalDocument | null;
  ringeval2ndLigne?: LegalDocument | null;
  kbis?: LegalDocument | null;
  urssaf?: LegalDocument | null;
  d1m?: LegalDocument | null;
  franchiseeProCard?: LegalDocument | null;
  amianteSs4?: LegalDocument | null;
  /** @format date-time */
  networkExitDate?: string | null;
  /** @format date-time */
  cfSignatureDate?: string | null;
}

export interface GeoPoint {
  /** @format float */
  lng?: number;
  /** @format float */
  lat?: number;
}

export interface Address {
  line1?: string | null;
  line2?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface CreateDateDto {
  /** @format date-time */
  date?: string | null;
}

export interface DisplayTodoStatsDto {
  /** @format int32 */
  contactInstanceTodos?: number;
  /** @format int32 */
  quoteTodos?: number;
  /** @format int32 */
  invoiceTodos?: number;
  /** @format int32 */
  visitReportTodos?: number;
  /** @format int32 */
  interventionsToBePlanned?: number;
}

export interface DisplayRevenuesDto {
  /** @format decimal */
  monthlyRevenues?: number;
  /** @format decimal */
  monthlyCeRevenues?: number;
  /** @format decimal */
  monthlyGeneralRevenues?: number;
  /** @format decimal */
  cumulativeRevenues?: number;
}

export interface DisplayAgencyTodoDto {
  interventionReference?: string | null;
  customerFullName?: string | null;
  city?: string | null;
  interventionInternalComment?: string | null;
  comment?: string | null;
  /** @format date-time */
  interventionRequestDate?: string;
  /** @format date-time */
  orderDate?: string | null;
  todoType?: InterventionTaskType;
}

export enum InterventionTaskType {
  CreateContactInstance = 'CreateContactInstance',
  CreateAppointment = 'CreateAppointment',
  CreateGcQuote = 'CreateGcQuote',
  AddWorkStartDate = 'AddWorkStartDate',
  CreateGcInvoice = 'CreateGcInvoice',
  QuotePhoneReminderAgency = 'QuotePhoneReminderAgency',
  QuoteMailReminderAgency = 'QuoteMailReminderAgency',
  VisitReportPhoneReminderAgency = 'VisitReportPhoneReminderAgency',
  VisitReportMailReminderAgency = 'VisitReportMailReminderAgency',
  ReceptionRecordPhoneReminderAgency = 'ReceptionRecordPhoneReminderAgency',
  ReceptionRecordMailReminderAgency = 'ReceptionRecordMailReminderAgency',
  PreventionFilePhoneReminderAgency = 'PreventionFilePhoneReminderAgency',
  PreventionFileMailReminderAgency = 'PreventionFileMailReminderAgency',
  SavPhoneReminderAgency = 'SavPhoneReminderAgency',
  SavMailReminderAgency = 'SavMailReminderAgency',
  InvoicePhoneReminderAgency = 'InvoicePhoneReminderAgency',
  InvoiceMailReminderAgency = 'InvoiceMailReminderAgency',
  CreateAgencyQuote = 'CreateAgencyQuote',
  UpdateAgencyQuote = 'UpdateAgencyQuote',
  CreateAgencyInvoice = 'CreateAgencyInvoice',
  UpdateAgencyInvoice = 'UpdateAgencyInvoice',
  UpdateGcInvoice = 'UpdateGcInvoice',
  UpdateGcQuote = 'UpdateGcQuote',
  CreateAgencyVisitReport = 'CreateAgencyVisitReport',
  UpdateAgencyVisitReport = 'UpdateAgencyVisitReport',
  CreateGcVisitReport = 'CreateGcVisitReport',
  UpdatedGcVisitReport = 'UpdatedGcVisitReport',
  QuotePhoneReminderCustomer = 'QuotePhoneReminderCustomer',
  QuoteMailReminderCustomer = 'QuoteMailReminderCustomer',
  VisitReportPhoneReminderCustomer = 'VisitReportPhoneReminderCustomer',
  VisitReportMailReminderCustomer = 'VisitReportMailReminderCustomer',
  ReceptionRecordPhoneReminderCustomer = 'ReceptionRecordPhoneReminderCustomer',
  ReceptionRecordMailReminderCustomer = 'ReceptionRecordMailReminderCustomer',
  PreventionFilePhoneReminderCustomer = 'PreventionFilePhoneReminderCustomer',
  PreventionFileMailReminderCustomer = 'PreventionFileMailReminderCustomer',
  SavPhoneReminderCustomer = 'SavPhoneReminderCustomer',
  SavMailReminderCustomer = 'SavMailReminderCustomer',
  InvoicePhoneReminderCustomer = 'InvoicePhoneReminderCustomer',
  InvoiceMailReminderCustomer = 'InvoiceMailReminderCustomer',
  DepositPhoneReminderCustomer = 'DepositPhoneReminderCustomer',
  DepositMailReminderCustomer = 'DepositMailReminderCustomer',
  DepositPhoneReminderAgency = 'DepositPhoneReminderAgency',
  DepositMailReminderAgency = 'DepositMailReminderAgency',
  UpdateAgencyDeposit = 'UpdateAgencyDeposit',
  CreateGcDeposit = 'CreateGcDeposit'
}

export interface CreateAgencyEmailDto {
  /** @format email */
  email?: string | null;
}

export interface CreateAgencyGeoLocationDto {
  /** @format float */
  lng?: number | null;
  /** @format float */
  lat?: number | null;
}

export interface DisplayAgencyPerformanceDto {
  contactInstance?: PerformanceIndicatorDto | null;
  quoteSubmission?: PerformanceIndicatorDto | null;
  workStartDate?: PerformanceIndicatorDto | null;
  invoiceSubmission?: PerformanceIndicatorDto | null;
}

export interface PerformanceIndicatorDto {
  /** @format int32 */
  passed?: number;
  /** @format int32 */
  failed?: number;
}

export interface PagedResultDtoOfDisplayAgencyPerformanceDetailsDto {
  values?: DisplayAgencyPerformanceDetailsDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayAgencyPerformanceDetailsDto {
  interventionReference?: string | null;
  customerFullName?: string | null;
  city?: string | null;
  interventionInternalComment?: string | null;
  comment?: string | null;
  /** @format date-time */
  interventionRequestDate?: string;
  /** @format date-time */
  orderDate?: string | null;
}

export enum PerformanceIndicator {
  PassedContactInstance = 'PassedContactInstance',
  FailedContactInstance = 'FailedContactInstance',
  PassedQuoteSubmission = 'PassedQuoteSubmission',
  FailedQuoteSubmission = 'FailedQuoteSubmission',
  PassedWorkStartDate = 'PassedWorkStartDate',
  FailedWorkStartDate = 'FailedWorkStartDate',
  PassedInvoiceSubmission = 'PassedInvoiceSubmission',
  FailedInvoiceSubmission = 'FailedInvoiceSubmission'
}

export interface CreateAgencyDepositPaymentDto {
  agencyDepositsReferences?: string[] | null;
  /** @format date-time */
  date?: string | null;
}

export interface CreateAgencyInvoicePaymentDto {
  agencyInvoicesReferences?: string[] | null;
  /** @format date-time */
  date?: string | null;
}

export interface DisplayBuildingDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  owners?: DisplayBuildingBelongingDto[] | null;
  occupants?: DisplayBuildingBelongingDto[] | null;
  geoLocation?: google.maps.LatLng | google.maps.LatLngLiteral;
  comments?: DisplayCommentDto[] | null;
  address?: DisplayAddressDto | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  /** @format int32 */
  assignedAgencyId?: number;
  files?: DisplayFileDto[] | null;
  /** @format int32 */
  billingAddressId?: number | null;
  billingAddress?: DisplayBillingAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  quoteDefaultBillingAddress?: QuoteDefaultBillingAddress;
  /** @format float */
  defaultTaxRate?: number | null;
  _IsDeleted?: boolean;
  preferredBillingAddressIds?: number[] | null;
  siteCode?: string | null;
}

export interface DisplayBuildingBelongingDto {
  address?: DisplayAddressDto | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
}

export enum PersonalTitle {
  Mr = 'Mr',
  Miss = 'Miss',
  Unspecified = 'Unspecified'
}

export interface DisplayBillingAddressDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isPreferred?: boolean;
  address?: DisplayAddressDto | null;
  comment?: string | null;
}

export enum QuoteDefaultBillingAddress {
  BuildingAddress = 'BuildingAddress',
  CustomerHqAddress = 'CustomerHqAddress'
}

export interface CreateBuildingDto {
  name?: string | null;
  address?: CreateAddressDto | null;
  geoLocation?: CreateGeoPointDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format int32 */
  customerId?: number;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  /** @format int32 */
  billingAddressId?: number;
  quoteDefaultBillingAddress?: QuoteDefaultBillingAddress | null;
  /** @format float */
  defaultTaxRate?: number | null;
  preferredBillingAddressIds?: number[] | null;
  siteCode?: string | null;
}

export interface CreateAddressDto {
  line1?: string | null;
  line2?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface CreateGeoPointDto {
  /** @format float */
  lng?: number;
  /** @format float */
  lat?: number;
}

export interface PagedResultDtoOfDisplayBuildingDto {
  values?: DisplayBuildingDto[] | null;
  /** @format int32 */
  count?: number;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type DisplayBuildingWithCustomerAndSignatoriesDto =
  DisplayBuildingWithCustomerDto & {
    signatories?: DisplayEmployeeDto[] | null;
  };

export type DisplayEmployeeDto = DisplayProfileDto & {
  personPosition?: PersonPosition;
  hasBillingNotification?: boolean;
  hasReminderNotification?: boolean;
  isPrimaryContact?: boolean;
  personFunction?: string | null;
};

export enum PersonPosition {
  Management = 'Management',
  Accounting = 'Accounting',
  Billing = 'Billing',
  Unspecified = 'Unspecified'
}

export interface DisplayProfileDto {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  personalTitle?: PersonalTitle;
}

export type DisplayBuildingWithCustomerDto = DisplayBuildingDto & {
  customer?: DisplayCustomerWithContractAndEmployeesDto | null;
};

export type DisplayCustomerWithContractAndEmployeesDto = DisplayCustomerDto & {
  contactsInfos?: DisplayEmployeeWithSignatoryBuildingsDto[] | null;
  contract?: DisplayContractDto | null;
};

export type DisplayEmployeeWithSignatoryBuildingsDto = DisplayEmployeeDto & {
  signatoryBuildings?: DisplayBuildingDto[] | null;
};

export interface DisplayContractDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format int32 */
  siteCount?: number;
  /** @format date-time */
  signatureDate?: string | null;
  /** @format int32 */
  duration?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  alertDate?: string | null;
  isAutoRenewed?: boolean | null;
  cePeriods?: DisplayDateRangeDto[] | null;
  isExclusive?: boolean | null;
  isFrameworkContract?: boolean | null;
  curativeBpuFile?: DisplayFileDto | null;
  ceBpuFile?: DisplayFileDto | null;
  rfas?: DisplayRfaDto[] | null;
  paymentDelay?: PaymentDelay | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  isInvoiceDunningAllowed?: boolean | null;
  decisionMaker?: DecisionMaker;
  hasCustomerAgreement?: boolean | null;
  hasOrderNumber?: boolean | null;
}

export interface DisplayDateRangeDto {
  from?: Month;
  to?: Month;
}

export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December'
}

export interface DisplayRfaDto {
  value?: string | null;
}

export interface PaymentDelay {
  paymentDelayStartingFrom?: PaymentDelayStartingFrom;
  /** @format int32 */
  daysOffset?: number;
  /** @format int32 */
  endOfMonthDaysOffset?: number;
}

export enum PaymentDelayStartingFrom {
  InvoiceDate = 'InvoiceDate',
  EndOfMounth = 'EndOfMounth'
}

export enum DecisionMaker {
  Local = 'Local',
  Central = 'Central'
}

export interface DisplayCustomerDto {
  /** @format int32 */
  id?: number;
  fullName?: string | null;
  activityDomain?: DisplayActivityDomainDto | null;
  activitySector?: DisplayActivitySectorDto | null;
  siret?: string | null;
  /** @format int32 */
  groupId?: number | null;
  group?: DisplayCustomerGroupDto | null;
  headquartersAddress?: DisplayAddressDto | null;
  billingAddresses?: DisplayBillingAddressDto[] | null;
  files?: DisplayFileDto[] | null;
  logoFiles?: DisplayFileDto[] | null;
  prospectionState?: ProspectionState;
  isActivityCentralised?: boolean | null;
  /** @format int32 */
  assignedAgentId?: number | null;
  /** @format float */
  defaultTaxRate?: number | null;
  isDisabled?: boolean;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  twimmKey?: string | null;
  comments?: DisplayCustomerCommentDto[] | null;
}

export interface DisplayActivitySectorDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface DisplayCustomerGroupDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  children?: DisplayCustomerGroupDto[] | null;
}

export enum ProspectionState {
  Authorized = 'Authorized',
  Unauthorized = 'Unauthorized',
  AuthorizedThroughAgc = 'AuthorizedThroughAgc'
}

export interface BuildingBelonging {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  address?: Address | null;
}

export interface CreateBuildingBelongingDto {
  address?: CreateAddressDto | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface PagedResultDtoOfDisplayInterventionDto {
  values?: DisplayInterventionDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayInterventionDto {
  /** @format int32 */
  id?: number;
  quoteType?: QuoteType;
  /** @format date-time */
  requestDate?: string | null;
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  assignedAgentId?: number | null;
  appointments?: DisplayInterventionEventDto[] | null;
  order?: DisplayDateWithMessageDto | null;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  isUrgent?: boolean;
  internalMessage?: string | null;
  hasAerialBucket?: boolean;
  period?: DisplayDateRangeDto | null;
  /** @format float */
  agencyAmount?: number;
  /** @format float */
  minorWorksBudget?: number;
  /** @format float */
  aerialBucketAmount?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  comments?: DisplayCommentDto[] | null;
  reference?: string | null;
  requestReferenceNumber?: string | null;
  contactInstances?: DisplayInterventionEventDto[] | null;
  agency?: DisplayAgencyDto | null;
  /** @format date-time */
  agencyAssignmentDate?: string | null;
  building?: DisplayBuildingWithCustomerDto | null;
  agencyVisitReports?: DisplayVisitReportIdentityDto[] | null;
  gcVisitReports?: DisplayGcVisitReportIdentityDto[] | null;
  receptionRecordFile?: DisplayFileDto | null;
  receptionRecordPredictions?: DisplayReceptionRecordPredictionsDto | null;
  preventionPlanFile?: DisplayFileDto | null;
  files?: DisplayFileDto[] | null;
  savs?: DisplaySavDto[] | null;
  hasPreventionPlan?: boolean;
  isDeleted?: boolean;
  hasAmiante?: boolean;
  hasCsps?: boolean;
  gcQuote?: DisplayQuoteIdentityWithPlannedDepositsDto | null;
  agencyQuote?: DisplayQuoteIdentityDto | null;
  agencyInvoices?: DisplayInvoiceIdentityDto[] | null;
  gcInvoices?: DisplayGcInvoiceIdentityDto[] | null;
  gcCreditNotes?: DisplayCreditNoteIdentityDto[] | null;
  isVisitReportRequired?: boolean;
  /** @format int32 */
  parentInterventionId?: number | null;
  parentInterventionReference?: string | null;
  gcDeposits?: DisplayGcDepositIdentityDto[] | null;
  agencyDeposits?: DisplayDepositIdentity[] | null;
  reminders?: DisplayReminderDto[] | null;
  terminationMotive?: string | null;
  isLost?: boolean;
  isPrematureInvoiceAllowed?: boolean;
  todo?: DisplayInterventionTaskDto[] | null;
  /** @format int32 */
  countInProgressTodo?: number;
  orderFiles?: DisplayFileDto | null;
  twimmId?: string | null;
}

export enum QuoteType {
  GeneralWithoutInfiltration = 'GeneralWithoutInfiltration',
  GeneralInfiltration = 'GeneralInfiltration',
  Diag = 'Diag',
  CeType = 'CeType',
  Ce = 'Ce',
  UrgentInfiltration = 'UrgentInfiltration',
  SupplementaryCe = 'SupplementaryCe',
  MinorWorks = 'MinorWorks'
}

export interface DisplayInterventionEventDto {
  /** @format date-time */
  date?: string;
  subject?: string | null;
  internalMessage?: string | null;
  participants?: DisplayEventParticipantDto[] | null;
}

export interface DisplayEventParticipantDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  personId?: number;
  isOwner?: boolean;
  participantType?: ParticipantType;
}

export enum ParticipantType {
  CustomerEmployee = 'CustomerEmployee',
  GcUser = 'GcUser'
}

export interface DisplayVisitReportIdentityDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format int32 */
  version?: number;
  /** @format date-time */
  validationDate?: string | null;
}

export type DisplayGcVisitReportIdentityDto = DisplayVisitReportIdentityDto &
  object;

export interface DisplayReceptionRecordPredictionsDto {
  predictionResults?: DisplayPredictionResultDto[] | null;
}

export interface DisplayPredictionResultDto {
  tagName?: string | null;
  /** @format double */
  value?: number;
}

export interface DisplaySavDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  declarationDate?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  files?: DisplayFileDto[] | null;
  receptionRecordsFiles?: DisplayFileDto[] | null;
}

export type DisplayQuoteIdentityWithPlannedDepositsDto =
  DisplayQuoteIdentityDto & {
    plannedDeposits?: DisplayPlannedDepositDto[] | null;
    comments?: DisplayCommentDto[] | null;
  };

export interface DisplayPlannedDepositDto {
  /** @format float */
  value?: number;
  type?: DepositType;
  /** @format date-time */
  date?: string | null;
  comment?: string | null;
}

export enum DepositType {
  Percent = 'Percent',
  Fixed = 'Fixed'
}

export interface DisplayQuoteIdentityDto {
  /** @format int32 */
  version?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
}

export interface DisplayInvoiceIdentityDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  version?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  /** @format date-time */
  refusalDate?: string | null;
}

export interface DisplayGcInvoiceIdentityDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  version?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  state?: GcInvoiceState;
  comments?: DisplayCommentDto[] | null;
}

export enum GcInvoiceState {
  AwaitingAgencyUpdate = 'AwaitingAgencyUpdate',
  InProgress = 'InProgress',
  Validated = 'Validated',
  Paid = 'Paid',
  Canceled = 'Canceled',
  NotDone = 'NotDone'
}

export interface DisplayCreditNoteIdentityDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  comments?: DisplayCommentDto[] | null;
}

export interface DisplayGcDepositIdentityDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  version?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  state?: GcDepositState;
  comments?: DisplayCommentDto[] | null;
}

export enum GcDepositState {
  AwaitingAgencyUpdate = 'AwaitingAgencyUpdate',
  InProgress = 'InProgress',
  Validated = 'Validated',
  Paid = 'Paid',
  Canceled = 'Canceled'
}

export interface DisplayDepositIdentity {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  validationDate?: string | null;
}

export interface DisplayReminderDto {
  /** @format int32 */
  id?: number;
  for?: ReminderKeys;
  type?: ReminderType;
  target?: ReminderTarget;
  mailSubject?: string | null;
  mailBody?: string | null;
  /** @format date-time */
  completionDate?: string | null;
  internalMessage?: string | null;
  /** @format int32 */
  targetEntityId?: number | null;
  invoiceReference?: string | null;
  depositReference?: string | null;
}

export enum ReminderKeys {
  InterventionQuote = 'InterventionQuote',
  InterventionVisitReport = 'InterventionVisitReport',
  InterventionReceptionRecord = 'InterventionReceptionRecord',
  InterventionPreventionPlanFile = 'InterventionPreventionPlanFile',
  InterventionSav = 'InterventionSav',
  InterventionInvoice = 'InterventionInvoice',
  InterventionDeposit = 'InterventionDeposit'
}

export enum ReminderType {
  Phone = 'Phone',
  Mail = 'Mail'
}

export enum ReminderTarget {
  Agency = 'Agency',
  Customer = 'Customer'
}

export interface DisplayInterventionTaskDto {
  /** @format int32 */
  id?: number;
  type?: InterventionTaskType;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  completionDate?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @format int32 */
  userProfileId?: number;
  user?: DisplayUserProfileDto | null;
  agencyDenomination?: string | null;
  comment?: string | null;
  isCompleted?: boolean;
}

export type DisplayUserProfileDto = DisplayProfileDto & {
  /** @format int32 */
  userId?: number;
};

export interface UpdateBuildingDto {
  name?: string | null;
  address?: UpdateAddressDto | null;
  geoLocation?: UpdateGeoPointDto | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  /** @format int32 */
  billingAddressId?: number;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  quoteDefaultBillingAddress?: QuoteDefaultBillingAddress | null;
  /** @format float */
  defaultTaxRate?: number | null;
  preferredBillingAddressIds?: number[] | null;
  siteCode?: string | null;
}

export interface UpdateAddressDto {
  line1?: string | null;
  line2?: string | null;
  zip?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface UpdateGeoPointDto {
  /** @format float */
  lng?: number;
  /** @format float */
  lat?: number;
}

export interface UpdateBuildingBelongingDto {
  address?: UpdateAddressDto | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface PagedResultDtoOfDisplayAllCreditNotesDto {
  values?: DisplayAllCreditNotesDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayAllCreditNotesDto {
  /** @format int32 */
  interventionId?: number;
  requestReferenceNumber?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  gcQuoteReference?: string | null;
  order?: DisplayDateWithMessageDto | null;
  reference?: string | null;
  isDeleted?: boolean;
  isLost?: boolean;
  quoteType?: QuoteType;
  customer?: string | null;
  buildingName?: string | null;
  buildingAddress?: DisplayAddressDto | null;
  creditNote?: DisplayGcCreditNoteDto | null;
  interventionComments?: DisplayCommentDto[] | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  assignedSalesAgent?: DisplayUserProfileDto | null;
}

export interface DisplayGcCreditNoteDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  paymentComment?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
  billingAddress?: DisplayAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  /** @format int32 */
  gcInvoiceId?: number | null;
  /** @format int32 */
  gcDepositId?: number | null;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  pdf?: DisplayFileDto | null;
  /** @format date-time */
  validationDate?: string | null;
  comments?: DisplayCommentDto[] | null;
  reduction?: DisplayReductionDto | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  creditNoteEntry?: DisplayCreditNoteEntryDto | null;
  reconciliation?: string | null;
}

export enum WorkType {
  Diagnostic = 'Diagnostic',
  Maintenance = 'Maintenance',
  Improvements = 'Improvements',
  Repair = 'Repair',
  ProtectiveMeasures = 'ProtectiveMeasures'
}

export interface DisplayQuoteOperationsGroupDto {
  /** @format int32 */
  id?: number;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: DisplayQuoteOperationDto[] | null;
}

export interface DisplayQuoteOperationDto {
  /** @format int32 */
  id?: number;
  description?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  unit?: Unit;
  photos?: DisplayFileDto[] | null;
  supplies?: DisplayQuoteOperationSupplyDto[] | null;
  operationUnitPrice?: number;
  isTryingtoUpdateUnitPrice?: boolean;
}

export enum Unit {
  Ml = 'Ml',
  MSquare = 'MSquare',
  MCube = 'MCube',
  U = 'U',
  Ens = 'Ens',
  Tonne = 'Tonne',
  Package = 'Package',
  L = 'L',
  Kg = 'Kg',
  Day = 'Day',
  Hour = 'Hour',
  Percent = 'Percent',
  Are = 'Are'
}

export interface DisplayQuoteOperationSupplyDto {
  /** @format int32 */
  id?: number;
  type?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  unit?: Unit;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
}

export interface DisplayReductionDto {
  /** @format decimal */
  value?: number;
  type?: ReductionType;
}

export enum ReductionType {
  Percent = 'Percent',
  Fixed = 'Fixed'
}

export interface DisplayCreditNoteEntryDto {
  /** @format int32 */
  id?: number;
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export enum InvoiceStateDto {
  Paid = 'Paid',
  AwaitingPayment = 'AwaitingPayment'
}

export interface DisplayCreditNoteDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: DisplayReductionDto | null;
  quoteOperationsGroup?: DisplayOperationGroupDto[] | null;
  /** @format decimal */
  totalHtBeforeReduction?: number | null;
  /** @format decimal */
  totalHt?: number | null;
  /** @format decimal */
  taxAmount?: number | null;
  /** @format decimal */
  totalTtc?: number | null;
  /** @format decimal */
  laborTotal?: number | null;
  /** @format decimal */
  suppliesTotal?: number | null;
  creditNoteEntry?: DisplayCreditNoteCalculationEntryDto | null;
  /** @format decimal */
  depositTotalHt?: number | null;
  /** @format decimal */
  depositTotalTtc?: number | null;
  /** @format decimal */
  leftToPayHt?: number | null;
  /** @format decimal */
  taxAmountLeftToPay?: number | null;
  /** @format decimal */
  leftToPayTtc?: number | null;
  depositDetails?: DisplayDepositDetail[] | null;
}

export interface DisplayOperationGroupDto {
  id?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: DisplayOperationDto[] | null;
  /** @format decimal */
  operationGroupTotal?: number | null;
  /** @format decimal */
  operationGroupSuppliesTotal?: number | null;
}

export interface DisplayOperationDto {
  id?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  supplies?: DisplayOperationSupplyDto[] | null;
  /** @format decimal */
  supplyTotal?: number | null;
  /** @format decimal */
  operationUnitPrice?: number | null;
  /** @format decimal */
  operationTotal?: number | null;
}

export interface DisplayOperationSupplyDto {
  id?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
  /** @format decimal */
  supplySalePrice?: number | null;
  /** @format decimal */
  supplyUnitPurchasePrice?: number | null;
  /** @format decimal */
  supplyUnitSalePrice?: number | null;
}

export interface DisplayCreditNoteCalculationEntryDto {
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateCreditNoteDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: CreateReductionDto | null;
  quoteOperationsGroup?: CreateOperationGroupDto[] | null;
  creditNoteEntry?: CreateCreditNoteCalculationEntryDto | null;
}

export interface CreateReductionDto {
  /** @format decimal */
  value?: number;
  type?: ReductionType | null;
}

export interface CreateOperationGroupDto {
  id?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: CreateOperationDto[] | null;
}

export interface CreateOperationDto {
  id?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  supplies?: CreateOperationSupplyDto[] | null;
}

export interface CreateOperationSupplyDto {
  id?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
}

export interface CreateCreditNoteCalculationEntryDto {
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateCreditNoteMarginRateDto {
  creditNote?: CreateCreditNoteDto;
  /** @format decimal */
  desiredTotal?: number;
}

export interface CreateCreditNoteReconciliationDto {
  creditNotesIds?: number[] | null;
  /** @format date-time */
  date?: string | null;
  /** @format int32 */
  reconciliationTypeId?: number;
}

export interface CreateCustomerGroupDto {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  parentId?: number;
}

export interface CreateCustomerDto {
  fullName?: string | null;
  /** @format int32 */
  activityDomainId?: number;
  /** @format int32 */
  activitySectorId?: number;
  siret?: string | null;
  /** @format int32 */
  groupId?: number | null;
  headquartersAddress?: CreateAddressDto | null;
  billingAddresses?: CreateBillingAddressDto[] | null;
  contactsInfos?: CreateEmployeeDto[] | null;
  contract?: CreateContractDto | null;
  prospectionState?: ProspectionState | null;
  isActivityCentralised?: boolean | null;
  /** @format int32 */
  assignedAgentId?: number | null;
  /** @format float */
  defaultTaxRate?: number | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
}

export interface CreateBillingAddressDto {
  name?: string | null;
  isPreferred?: boolean;
  address?: CreateAddressDto | null;
  comment?: string | null;
}

export interface CreateEmployeeDto {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  personPosition?: PersonPosition;
  hasBillingNotification?: boolean | null;
  hasReminderNotification?: boolean | null;
  isSignatory?: boolean | null;
  isPrimaryContact?: boolean | null;
  personalTitle?: PersonalTitle;
  personFunction?: string | null;
}

export interface CreateContractDto {
  reference?: string | null;
  /** @format int32 */
  siteCount?: number;
  /** @format date-time */
  signatureDate?: string | null;
  /** @format int32 */
  duration?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  alertDate?: string | null;
  isAutoRenewed?: boolean | null;
  cePeriods?: CreateDateRangeDto[] | null;
  isExclusive?: boolean | null;
  isFrameworkContract?: boolean | null;
  rfas?: CreateRfaDto[] | null;
  paymentDelay?: CreatePaymentDelayDto | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  isInvoiceDunningAllowed?: boolean | null;
  decisionMaker?: DecisionMaker | null;
  hasCustomerAgreement?: boolean | null;
  hasOrderNumber?: boolean | null;
}

export interface CreateDateRangeDto {
  from?: Month | null;
  to?: Month | null;
}

export interface CreateRfaDto {
  value?: string | null;
}

export interface CreatePaymentDelayDto {
  paymentDelayStartingFrom?: PaymentDelayStartingFrom | null;
  /** @format int32 */
  daysOffset?: number;
  /** @format int32 */
  endOfMonthDaysOffset?: number;
}

export interface PagedResultDtoOfDisplayCustomerDto {
  values?: DisplayCustomerDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface UpdateCustomerDto {
  fullName?: string | null;
  /** @format int32 */
  activityDomainId?: number | null;
  /** @format int32 */
  activitySectorId?: number;
  siret?: string | null;
  /** @format int32 */
  groupId?: number | null;
  headquartersAddress?: UpdateAddressDto | null;
  contract?: UpdateContractDto | null;
  prospectionState?: ProspectionState | null;
  isActivityCentralised?: boolean | null;
  /** @format int32 */
  assignedAgentId?: number | null;
  /** @format float */
  defaultTaxRate?: number | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
}

export interface UpdateContractDto {
  reference?: string | null;
  /** @format int32 */
  siteCount?: number;
  /** @format date-time */
  signatureDate?: string | null;
  /** @format int32 */
  duration?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  alertDate?: string | null;
  isAutoRenewed?: boolean | null;
  cePeriods?: UpdateDateRangeDto[] | null;
  isExclusive?: boolean | null;
  isFrameworkContract?: boolean | null;
  rfas?: UpdateRfaDto[] | null;
  paymentDelay?: UpdatePaymentDelayDto | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  isInvoiceDunningAllowed?: boolean | null;
  decisionMaker?: DecisionMaker | null;
  hasCustomerAgreement?: boolean | null;
  hasOrderNumber?: boolean | null;
}

export interface UpdateDateRangeDto {
  from?: Month | null;
  to?: Month | null;
}

export interface UpdateRfaDto {
  value?: string | null;
}

export interface UpdatePaymentDelayDto {
  paymentDelayStartingFrom?: PaymentDelayStartingFrom | null;
  /** @format int32 */
  daysOffset?: number;
  /** @format int32 */
  endOfMonthDaysOffset?: number;
}

export interface UpdateEmployeeDto {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  personPosition?: PersonPosition;
  hasBillingNotification?: boolean | null;
  hasReminderNotification?: boolean | null;
  isPrimaryContact?: boolean | null;
  personalTitle?: PersonalTitle;
  personFunction?: string | null;
}

export type CustomerEmployeeProfile = Profile & {
  personPosition?: PersonPosition;
  hasBillingNotification?: boolean | null;
  hasReminderNotification?: boolean | null;
  personFunction?: string | null;
  isPrimaryContact?: boolean | null;
  signatoryBuildings?: Building[] | null;
};

export interface Building {
  /** @format int32 */
  id?: number;
  name?: string | null;
  owners?: BuildingBelonging[] | null;
  occupants?: BuildingBelonging[] | null;
  geoLocation?: GeoPoint | null;
  comments?: Comment[] | null;
  address?: Address | null;
  customer?: Customer | null;
  /** @format int32 */
  customerId?: number | null;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  assignedAgencyId?: number;
  files?: File[] | null;
  signatories?: CustomerEmployeeProfile[] | null;
  interventions?: Intervention[] | null;
  /** @format int32 */
  billingAddressId?: number | null;
  quoteDefaultBillingAddress?: QuoteDefaultBillingAddress;
  billingAddress?: BillingAddress | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  _IsDeleted?: boolean;
  /** @format float */
  defaultTaxRate?: number | null;
  preferredBillingAddressIds?: PreferredBillingAddressId[] | null;
}

export interface Customer {
  /** @format int32 */
  id?: number;
  fullName?: string | null;
  activityDomain?: ActivityDomain | null;
  /** @format int32 */
  activityDomainId?: number | null;
  activitySector?: ActivitySector | null;
  /** @format int32 */
  activitySectorId?: number | null;
  siret?: string | null;
  /** @format int32 */
  groupId?: number | null;
  group?: CustomerGroup | null;
  employees?: CustomerEmployeeProfile[] | null;
  headquartersAddress?: Address | null;
  billingAddresses?: BillingAddress[] | null;
  contract?: Contract | null;
  buildings?: Building[] | null;
  files?: File[] | null;
  logoFiles?: File[] | null;
  prospectionState?: ProspectionState;
  isActivityCentralised?: boolean | null;
  salesEvents?: SalesEvent[] | null;
  /** @format int32 */
  assignedAgentId?: number | null;
  assignedAgent?: UserProfile | null;
  /** @format float */
  defaultTaxRate?: number | null;
  isDisabled?: boolean;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  assignedSalesAgent?: UserProfile | null;
  twimmKey?: string | null;
}

export interface ActivityDomain {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  activitySectorId?: number;
}

export interface ActivitySector {
  /** @format int32 */
  id?: number;
  name?: string | null;
  activityDomains?: ActivityDomain[] | null;
}

export interface CustomerGroup {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  parentId?: number | null;
  parent?: CustomerGroup | null;
  children?: CustomerGroup[] | null;
}

export interface BillingAddress {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isPreferred?: boolean;
  address?: Address | null;
}

export interface Contract {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format int32 */
  siteCount?: number;
  /** @format date-time */
  signatureDate?: string | null;
  /** @format int32 */
  duration?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  alertDate?: string | null;
  isAutoRenewed?: boolean | null;
  cePeriods?: DateRange[] | null;
  isExclusive?: boolean | null;
  isFrameworkContract?: boolean | null;
  curativeBpuFile?: File | null;
  ceBpuFile?: File | null;
  rfas?: Rfa[] | null;
  paymentDelay?: PaymentDelay | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  isInvoiceDunningAllowed?: boolean | null;
  decisionMaker?: DecisionMaker;
  hasCustomerAgreement?: boolean | null;
  hasOrderNumber?: boolean | null;
}

export interface DateRange {
  from?: Month;
  to?: Month;
}

export interface Rfa {
  value?: string | null;
}

export type SalesEvent = CalendarEvent & {
  type?: SalesEventType;
  communicationChannel?: EventCommunicationChannel;
};

export enum SalesEventType {
  DiscoveryAndProspection = 'DiscoveryAndProspection',
  PortfolioDevelopment = 'PortfolioDevelopment',
  Defence = 'Defence',
  CustomerSatisfaction = 'CustomerSatisfaction',
  SAV = 'SAV',
  Technical = 'Technical'
}

export enum EventCommunicationChannel {
  Physical = 'Physical',
  Online = 'Online'
}

export interface CalendarEvent {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  subject?: string | null;
  message?: string | null;
  internalMessage?: string | null;
  participants?: EventParticipant[] | null;
  shouldNotifyByEmail?: boolean | null;
}

export interface EventParticipant {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  personId?: number;
  isOwner?: boolean;
  participantType?: ParticipantType;
}

export type UserProfile = Profile & {
  /** @format int32 */
  userId?: number;
  role?: string | null;
  ownerCalendarEvents?: CalendarEvent[] | null;
};

export interface Profile {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  personalTitle?: PersonalTitle;
  guestCalendarEvent?: CalendarEvent[] | null;
}

export interface Intervention {
  /** @format int32 */
  id?: number;
  quoteType?: QuoteType;
  /** @format date-time */
  requestDate?: string | null;
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  order?: DateWithMessage | null;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  isUrgent?: boolean;
  internalMessage?: string | null;
  /** @format int32 */
  buildingId?: number | null;
  building?: Building | null;
  hasAerialBucket?: boolean;
  period?: DateRange | null;
  /** @format float */
  agencyAmount?: number;
  /** @format float */
  minorWorksBudget?: number;
  /** @format float */
  aerialBucketAmount?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  comments?: Comment[] | null;
  reference?: string | null;
  contactInstances?: InterventionEvent[] | null;
  appointments?: InterventionEvent[] | null;
  agency?: Agency | null;
  /** @format int32 */
  agencyId?: number | null;
  /** @format date-time */
  agencyAssignmentDate?: string | null;
  files?: File[] | null;
  agencyVisitReports?: AgencyVisitReport[] | null;
  gcVisitReports?: GcVisitReport[] | null;
  receptionRecordFile?: File | null;
  receptionRecordPredictions?: ReceptionRecordPredictions | null;
  preventionPlanFile?: File | null;
  isDeleted?: boolean;
  hasPreventionPlan?: boolean;
  hasAmiante?: boolean;
  savs?: Sav[] | null;
  hasCsps?: boolean;
  gcQuote?: GcQuote | null;
  agencyQuote?: AgencyQuote | null;
  agencyInvoices?: AgencyInvoice[] | null;
  gcInvoices?: GcInvoice[] | null;
  gcCreditNotes?: GcCreditNote[] | null;
  /** @format int32 */
  assignedAgentId?: number | null;
  todo?: InterventionTask[] | null;
  requestReferenceNumber?: string | null;
  isVisitReportRequired?: boolean;
  /** @format int32 */
  parentInterventionId?: number | null;
  gcDeposits?: GcDeposit[] | null;
  agencyDeposits?: AgencyDeposit[] | null;
  reminders?: Reminder[] | null;
  isLost?: boolean;
  terminationMotive?: string | null;
  isPrematureInvoiceAllowed?: boolean;
}

export type InterventionEvent = CalendarEvent & object;

export type AgencyVisitReport = VisitReport & {
  /** @format int32 */
  interventionId?: number | null;
  isUnlocked?: boolean;
  gcVisitReport?: GcVisitReport | null;
  /** @format date-time */
  validationDate?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
};

export type GcVisitReport = VisitReport & {
  /** @format int32 */
  interventionId?: number | null;
  contactInfo?: ContactInfo | null;
  /** @format date-time */
  validationDate?: string | null;
  pdf?: File | null;
  address?: Address | null;
  /** @format int32 */
  agencyVisitReportId?: number | null;
  agencyVisitReport?: AgencyVisitReport | null;
  state?: GcVisitReportState;
};

export interface ContactInfo {
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
}

export enum GcVisitReportState {
  AwaitingAgencyUpdate = 'AwaitingAgencyUpdate',
  InProgress = 'InProgress',
  Validated = 'Validated',
  NotDone = 'NotDone'
}

export interface VisitReport {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  description?: string | null;
  roofViews?: OrderablePhoto[] | null;
  satelliteViews?: OrderablePhoto[] | null;
  diagnostic?: DiagnosticLine[] | null;
  conclusion?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  name?: string | null;
  /** @format int32 */
  version?: number;
}

export interface OrderablePhoto {
  /** @format int32 */
  order?: number;
  photo?: File | null;
}

export interface DiagnosticLine {
  /** @format int32 */
  id?: number;
  title?: string | null;
  problem?: string | null;
  solution?: string | null;
  problemPhotos?: OrderablePhoto[] | null;
  solutionPhotos?: OrderablePhoto[] | null;
}

export interface ReceptionRecordPredictions {
  predictionResults?: PredictionResult[] | null;
}

export interface PredictionResult {
  tagName?: string | null;
  /** @format double */
  value?: number;
}

export interface Sav {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  declarationDate?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  files?: File[] | null;
  receptionRecordsFiles?: File[] | null;
}

export type GcQuote = Quote & {
  /** @format int32 */
  interventionId?: number | null;
  billingAddress?: Address | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format float */
  marginRate?: number;
  /** @format date-time */
  creationDate?: string | null;
  plannedDeposits?: PlannedDeposit[] | null;
  reduction?: Reduction | null;
  /** @format float */
  taxRate?: number;
  contactInfo?: ContactInfo | null;
  /** @format date-time */
  validationDate?: string | null;
  pdf?: File | null;
  state?: GcQuoteState;
  comments?: Comment[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
};

export interface PlannedDeposit {
  /** @format float */
  value?: number;
  type?: DepositType;
  /** @format date-time */
  date?: string | null;
  comment?: string | null;
}

export interface Reduction {
  /** @format decimal */
  value?: number;
  type?: ReductionType;
}

export enum GcQuoteState {
  AwaitingAgencyUpdate = 'AwaitingAgencyUpdate',
  InProgress = 'InProgress',
  Validated = 'Validated',
  NotDone = 'NotDone'
}

export interface Quote {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  siteAddress?: Address | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: File[] | null;
  quoteOperationsGroup?: QuoteOperationsGroup[] | null;
  quoteConditions?: QuoteCondition[] | null;
  /** @format int32 */
  version?: number;
  intervention?: Intervention | null;
}

export interface QuoteOperationsGroup {
  /** @format int32 */
  id?: number;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: QuoteOperation[] | null;
}

export interface QuoteOperation {
  /** @format int32 */
  id?: number;
  description?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  unit?: Unit;
  photos?: File[] | null;
  supplies?: QuoteOperationSupply[] | null;
}

export interface QuoteOperationSupply {
  /** @format int32 */
  id?: number;
  type?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  unit?: Unit;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
}

export interface QuoteCondition {
  /** @format int32 */
  number?: number;
  title?: string | null;
  content?: string | null;
}

export type AgencyQuote = Quote & {
  /** @format int32 */
  interventionId?: number | null;
  isUnlocked?: boolean;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  reduction?: Reduction | null;
};

export type AgencyInvoice = Invoice & {
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  gcInvoices?: GcInvoice[] | null;
  isUnlocked?: boolean;
  /** @format date-time */
  validationDate?: string | null;
  invoicePaymentAuthorizations?: InvoicePaymentAuthorization[] | null;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format decimal */
  total?: number;
  reduction?: Reduction | null;
  refusalReason?: string | null;
  /** @format date-time */
  refusalDate?: string | null;
};

export type GcInvoice = Invoice & {
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  /** @format int32 */
  agencyInvoiceId?: number | null;
  agencyInvoice?: AgencyInvoice | null;
  billingAddress?: Address | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format float */
  taxRate?: number;
  reduction?: Reduction | null;
  deposits?: GcDeposit[] | null;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
  paymentComment?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format date-time */
  validationDate?: string | null;
  customerPaymentInstallments?: CustomerPaymentInstallment[] | null;
  comments?: Comment[] | null;
  gcCreditNotes?: GcCreditNote[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  /** @format decimal */
  depositTotalHt?: number;
  /** @format decimal */
  depositTotalTtc?: number;
  /** @format decimal */
  taxAmountLeftToPay?: number;
  /** @format decimal */
  leftToPayTtc?: number;
  /** @format decimal */
  remainingPaymentTtc?: number;
  reminders?: Reminder[] | null;
};

export type GcDeposit = Deposit & {
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  /** @format int32 */
  agencyDepositId?: number | null;
  agencyDeposit?: AgencyDeposit | null;
  /** @format date-time */
  paymentDate?: string | null;
  paymentComment?: string | null;
  /** @format int32 */
  gcInvoiceId?: number | null;
  gcInvoice?: GcInvoice | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format date-time */
  paymentDueDate?: string | null;
  quoteConditions?: QuoteCondition[] | null;
  billingAddress?: Address | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format float */
  taxRate?: number;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  comments?: Comment[] | null;
  customerPaymentInstallments?: CustomerPaymentInstallment[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  /** @format decimal */
  remainingPaymentTtc?: number;
  gcCreditNotes?: GcCreditNote[] | null;
  reminders?: Reminder[] | null;
};

export type AgencyDeposit = Deposit & {
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  isUnlocked?: boolean;
  gcDeposit?: GcDeposit | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format date-time */
  paymentDate?: string | null;
  depositPaymentAuthorizations?: InvoicePaymentAuthorization[] | null;
  /** @format decimal */
  total?: number;
};

export interface InvoicePaymentAuthorization {
  state?: InvoicePaymentAuthorizationState;
  /** @format date-time */
  date?: string;
  motive?: string | null;
  /** @format int32 */
  agencyInvoiceId?: number | null;
  agencyInvoice?: AgencyInvoice | null;
}

export enum InvoicePaymentAuthorizationState {
  Authorized = 'Authorized',
  Unauthorized = 'Unauthorized'
}

export interface Deposit {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  comment?: string | null;
  internalMessage?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  pdf?: File | null;
  siteAddress?: Address | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  warning?: string | null;
  photos?: File[] | null;
  quoteOperationsGroup?: QuoteOperationsGroup[] | null;
  depositEntry?: DepositEntry | null;
  /** @format int32 */
  depositEntryId?: number | null;
  /** @format int32 */
  version?: number;
}

export interface DepositEntry {
  /** @format int32 */
  id?: number;
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CustomerPaymentInstallment {
  /** @format date-time */
  date?: string;
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  gcInvoiceId?: number | null;
  gcInvoice?: GcInvoice | null;
}

export interface GcCreditNote {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  siteAddress?: Address | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: File[] | null;
  quoteOperationsGroup?: QuoteOperationsGroup[] | null;
  /** @format date-time */
  creationDate?: string | null;
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  billingAddress?: Address | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format float */
  taxRate?: number;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format date-time */
  validationDate?: string | null;
  pdf?: File | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  /** @format date-time */
  paymentDate?: string | null;
  paymentComment?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format int32 */
  gcInvoiceId?: number | null;
  /** @format int32 */
  gcDepositId?: number | null;
  comments?: Comment[] | null;
  reduction?: Reduction | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  /** @format int32 */
  creditNoteEntryId?: number | null;
  creditNoteEntry?: CreditNoteEntry | null;
  gcDeposit?: GcDeposit | null;
}

export interface CreditNoteEntry {
  /** @format int32 */
  id?: number;
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface Reminder {
  /** @format int32 */
  id?: number;
  for?: ReminderKeys;
  type?: ReminderType;
  target?: ReminderTarget;
  mailSubject?: string | null;
  mailBody?: string | null;
  /** @format date-time */
  completionDate?: string | null;
  internalMessage?: string | null;
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
  /** @format int32 */
  targetEntityId?: number | null;
}

export interface Invoice {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  siteAddress?: Address | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: File[] | null;
  quoteOperationsGroup?: QuoteOperationsGroup[] | null;
  /** @format int32 */
  version?: number;
  /** @format date-time */
  creationDate?: string | null;
  pdf?: File | null;
}

export interface InterventionTask {
  /** @format int32 */
  id?: number;
  type?: InterventionTaskType;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  completionDate?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @format int32 */
  userProfileId?: number | null;
  comment?: string | null;
  isCompleted?: boolean;
  /** @format int32 */
  interventionId?: number | null;
  intervention?: Intervention | null;
}

export interface PreferredBillingAddressId {
  /** @format int32 */
  id?: number;
}

export interface DisplaySalesEventDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  subject?: string | null;
  message?: string | null;
  internalMessage?: string | null;
  type?: SalesEventType;
  communicationChannel?: EventCommunicationChannel;
  participants?: DisplayEventParticipantDto[] | null;
  shouldNotifyByEmail?: boolean | null;
}

export interface CreateSalesEventDto {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  subject?: string | null;
  message?: string | null;
  internalMessage?: string | null;
  participants?: CreateEventParticipantDto[] | null;
  type?: SalesEventType | null;
  communicationChannel?: EventCommunicationChannel | null;
  shouldNotifyByEmail?: boolean | null;
}

export interface CreateEventParticipantDto {
  /** @format int32 */
  personId?: number;
  isOwner?: boolean;
  participantType?: ParticipantType;
}

export interface UpdateEventParticipantDto {
  /** @format int32 */
  personId?: number;
  isOwner?: boolean;
  participantType?: ParticipantType;
}

export interface UpdateSalesEventDto {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  subject?: string | null;
  message?: string | null;
  internalMessage?: string | null;
  type?: SalesEventType | null;
  communicationChannel?: EventCommunicationChannel | null;
}

export interface UpdateBillingAddressDto {
  name?: string | null;
  isPreferred?: boolean;
  address?: UpdateAddressDto | null;
  comment?: string | null;
}

export interface PagedResultDtoOfDisplayCustomerInterventionsGcQuoteDto {
  values?: DisplayCustomerInterventionsGcQuoteDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayCustomerInterventionsGcQuoteDto {
  order?: DisplayDateWithMessageDto | null;
  reference?: string | null;
  isDeleted?: boolean;
  gcQuote?: DisplayGcQuoteDto | null;
}

export interface DisplayGcQuoteDto {
  reference?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  billingAddress?: DisplayAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format float */
  taxRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: DisplayReductionDto | null;
  comment?: string | null;
  warning?: string | null;
  plannedDeposits?: DisplayPlannedDepositDto[] | null;
  /** @format float */
  marginRate?: number;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  version?: number;
  quoteConditions?: DisplayQuoteConditionDto[] | null;
  contactInfo?: DisplayContactInfoDto | null;
  pdf?: DisplayFileDto | null;
  /** @format date-time */
  validationDate?: string | null;
  state?: GcQuoteState;
  comments?: DisplayCommentDto[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
}

export interface DisplayQuoteConditionDto {
  /** @format int32 */
  number?: number;
  title?: string | null;
  content?: string | null;
}

export interface DisplayContactInfoDto {
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
}

export enum InterventionState {
  Accepted = 'Accepted',
  Invoiced = 'Invoiced',
  InProgress = 'InProgress',
  Deleted = 'Deleted',
  AwaitingAgencyUpdate = 'AwaitingAgencyUpdate',
  AcceptedNotPaid = 'AcceptedNotPaid'
}

export interface PagedResultDtoOfDisplayCustomersTodosDto {
  values?: DisplayCustomersTodosDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayCustomersTodosDto {
  fullName?: string | null;
  todo?: DisplayCustomerTaskDto[] | null;
}

export interface DisplayCustomerTaskDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  type?: InterventionTaskType;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  completionDate?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @format int32 */
  userProfileId?: number;
  user?: DisplayUserProfileDto | null;
  agencyName?: string | null;
  comment?: string | null;
  isCompleted?: boolean;
  quoteType: QuoteType;
}

export interface DisplayQuoteTileDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  date?: string | null;
  tier?: string | null;
  /** @format date-time */
  tierDateProspect?: string | null;
  /** @format date-time */
  tierDateClient?: string | null;
  /** @format int32 */
  idTier?: number;
  /** @format decimal */
  montantHt?: number;
  /** @format decimal */
  heureDevise?: number;
  /** @format int32 */
  idEmploye?: number;
}

export interface DisplayInvoiceTileDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @format date-time */
  dateEcheance?: string | null;
  tier?: string | null;
  /** @format int32 */
  idTier?: number;
  /** @format decimal */
  montantHt?: number;
  /** @format decimal */
  montantTTC?: number;
  /** @format decimal */
  resteAPaye?: number | null;
  /** @format decimal */
  nombreHeure?: number;
  /** @format int32 */
  idEmploye?: number;
  type?: GcInvoiceTypeEnum;
  referenceCommande?: string | null;
  etat?: GcInvoiceEtatEnum;
  typeFactureSource?: GcInvoiceTypeEnum | null;
}

export enum GcInvoiceTypeEnum {
  Acompte = 'Acompte',
  Facture = 'Facture',
  Avoir = 'Avoir'
}

export enum GcInvoiceEtatEnum {
  EnAttente = 'En_Attente',
  Imprime = 'Imprime',
  Paye = 'Paye',
  Annule = 'Annule'
}

export interface DisplayCommandeTileDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  date?: string | null;
  tier?: string | null;
  /** @format int32 */
  idTier?: number;
  /** @format decimal */
  montantHt?: number;
  /** @format decimal */
  nbHeureCommande?: number;
  /** @format int32 */
  idEmploye?: number;
  isCE?: boolean;
  factures?: DisplayInvoiceCommandeDto[] | null;
}

export interface DisplayInvoiceCommandeDto {
  reference?: string | null;
  /** @format decimal */
  montantHt?: number;
  /** @format decimal */
  nombreHeure?: number;
}

export interface PagedResultDtoOfDisplayAllDepositsDto {
  values?: DisplayAllDepositsDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayAllDepositsDto {
  /** @format int32 */
  interventionId?: number;
  requestReferenceNumber?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  gcQuoteReference?: string | null;
  order?: DisplayDateWithMessageDto | null;
  reference?: string | null;
  isDeleted?: boolean;
  isLost?: boolean;
  quoteType?: QuoteType;
  customer?: string | null;
  buildingName?: string | null;
  agencyName?: string | null;
  /** @format int32 */
  agencyId?: number;
  buildingAddress?: DisplayAddressDto | null;
  deposit?: DisplayDepositDto | null;
  agencyDeposit?: DisplayAgencyDepositDto | null;
  reminders?: DisplayReminderDto[] | null;
  interventionComments?: DisplayCommentDto[] | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  assignedSalesAgent?: DisplayUserProfileDto | null;
}

export interface DisplayDepositDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  comment?: string | null;
  paymentComment?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
  internalMessage?: string | null;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: DisplayAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  quoteConditions?: DisplayQuoteConditionDto[] | null;
  /** @format int32 */
  gcInvoiceId?: number | null;
  /** @format int32 */
  interventionId?: number;
  /** @format int32 */
  agencyDepositId?: number | null;
  /** @format date-time */
  validationDate?: string | null;
  /** @format int32 */
  version?: number;
  pdf?: DisplayFileDto | null;
  depositEntry?: DisplayDepositEntryDto | null;
  customerPaymentInstallments?: DisplayCustomerPaymentInstallmentDto[] | null;
  comments?: DisplayCommentDto[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  /** @format decimal */
  remainingPaymentTtc?: number;
  state?: GcDepositState;
  agencyDepositPaymentAuthorizations?:
    | DisplayInvoicePaymentAuthorizationDto[]
    | null;
  /** @format date-time */
  agencyDepositPaymentDate?: string | null;
  reminders?: DisplayReminderDto[] | null;
  reconciliation?: string | null;
}

export interface DisplayDepositEntryDto {
  /** @format int32 */
  id?: number;
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface DisplayCustomerPaymentInstallmentDto {
  /** @format date-time */
  date?: string;
  /** @format decimal */
  amount?: number;
}

export interface DisplayInvoicePaymentAuthorizationDto {
  state?: InvoicePaymentAuthorizationState;
  /** @format date-time */
  date?: string;
  motive?: string | null;
}

export interface DisplayAgencyDepositDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  version?: number;
  isUnlocked?: boolean;
  /** @format date-time */
  validationDate?: string | null;
  pdf?: DisplayFileDto | null;
  depositEntry?: DisplayDepositEntryDto | null;
  gcDeposit?: DisplayGcDepositIdentityDto | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  depositPaymentAuthorizations?: DisplayInvoicePaymentAuthorizationDto[] | null;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format decimal */
  total?: number;
}

export enum PaymentAuthorizationState {
  Unauthorized = 'Unauthorized',
  Authorized = 'Authorized',
  Unprocessed = 'Unprocessed'
}

export interface DisplayDepositCalculationDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  quoteOperationsGroup?: DisplayOperationGroupDto[] | null;
  depositEntry?: DisplayDepositEntryCalculationDto | null;
  /** @format decimal */
  totalHt?: number | null;
  /** @format decimal */
  taxAmount?: number | null;
  /** @format decimal */
  totalTtc?: number | null;
  /** @format decimal */
  laborTotal?: number | null;
  /** @format decimal */
  suppliesTotal?: number | null;
}

export interface DisplayDepositEntryCalculationDto {
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateDepositCalculationDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  quoteOperationsGroup?: CreateOperationGroupDto[] | null;
  depositEntry?: CreateDepositEntryCalculationDto | null;
}

export interface CreateDepositEntryCalculationDto {
  /** @format decimal */
  amount?: number;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateDepositMarginRateDto {
  deposit?: CreateDepositCalculationDto;
  /** @format decimal */
  desiredTotal?: number;
}

export interface CreateDepositReconciliationDto {
  depositsIds?: number[] | null;
  /** @format date-time */
  date?: string | null;
  /** @format int32 */
  reconciliationTypeId?: number;
}

export interface PagedResultDtoOfObject {
  values?: any[] | null;
  /** @format int32 */
  count?: number;
}

export enum EventsLogType {
  AgencyDepositPaymentAuthorizationEvent = 'AgencyDepositPaymentAuthorizationEvent',
  AgencyDepositUnlockedEvent = 'AgencyDepositUnlockedEvent',
  AgencyDepositValidatedEvent = 'AgencyDepositValidatedEvent',
  AgencyInvoicePaymentAuthorizationEvent = 'AgencyInvoicePaymentAuthorizationEvent',
  AgencyInvoiceUnlockedEvent = 'AgencyInvoiceUnlockedEvent',
  AgencyInvoiceValidatedEvent = 'AgencyInvoiceValidatedEvent',
  AgencyQuoteUnlockedEvent = 'AgencyQuoteUnlockedEvent',
  AgencyVisitReportUnlockedEvent = 'AgencyVisitReportUnlockedEvent',
  GcCreditNoteCreatedEvent = 'GcCreditNoteCreatedEvent',
  GcCreditNoteUpdatedEvent = 'GcCreditNoteUpdatedEvent',
  GcCreditNoteValidatedEvent = 'GcCreditNoteValidatedEvent',
  GcDepositCreatedEvent = 'GcDepositCreatedEvent',
  GcDepositCustomerPaymentInstallmentAddedEvent = 'GcDepositCustomerPaymentInstallmentAddedEvent',
  GcDepositUpdatedEvent = 'GcDepositUpdatedEvent',
  GcDepositValidatedEvent = 'GcDepositValidatedEvent',
  GcInvoiceCreatedEvent = 'GcInvoiceCreatedEvent',
  GcInvoiceCustomerPaymentInstallmentAddedEvent = 'GcInvoiceCustomerPaymentInstallmentAddedEvent',
  GcInvoiceUpdatedEvent = 'GcInvoiceUpdatedEvent',
  GcInvoiceValidatedEvent = 'GcInvoiceValidatedEvent',
  GcQuoteCreatedEvent = 'GcQuoteCreatedEvent',
  GcQuoteUnlockedEvent = 'GcQuoteUnlockedEvent',
  GcQuoteUpdatedEvent = 'GcQuoteUpdatedEvent',
  GcQuoteValidatedEvent = 'GcQuoteValidatedEvent',
  GcVisitReportCreatedEvent = 'GcVisitReportCreatedEvent',
  GcVisitReportUpdatedEvent = 'GcVisitReportUpdatedEvent',
  GcVisitReportValidatedEvent = 'GcVisitReportValidatedEvent',
  OrderCreatedEvent = 'OrderCreatedEvent',
  OrderDeletedEvent = 'OrderDeletedEvent',
  OrderUpdatedEvent = 'OrderUpdatedEvent',
  InterventionAgencyAssignedEvent = 'InterventionAgencyAssignedEvent',
  InterventionCreatedEvent = 'InterventionCreatedEvent',
  InterventionDeletedEvent = 'InterventionDeletedEvent',
  InterventionLostEvent = 'InterventionLostEvent',
  InterventionPreventionPlanAddedEvent = 'InterventionPreventionPlanAddedEvent',
  InterventionReceptionRecordAddedEvent = 'InterventionReceptionRecordAddedEvent',
  InterventionRestoredEvent = 'InterventionRestoredEvent',
  InterventionUpdatedEvent = 'InterventionUpdatedEvent',
  BuildingCreatedEvent = 'BuildingCreatedEvent',
  InterventionFileAddedEvent = 'InterventionFileAddedEvent',
  SavCreatedEvent = 'SavCreatedEvent',
  InterventionFileRemovedEvent = 'InterventionFileRemovedEvent',
  InterventionReceptionRecordRemovedEvent = 'InterventionReceptionRecordRemovedEvent',
  InterventionPreventionPlanRemovedEvent = 'InterventionPreventionPlanRemovedEvent',
  SavFileAddedEvent = 'SavFileAddedEvent',
  SavFileRemovedEvent = 'SavFileRemovedEvent',
  SavReceptionRecordFileAddedEvent = 'SavReceptionRecordFileAddedEvent',
  SavReceptionRecordFileRemovedEvent = 'SavReceptionRecordFileRemovedEvent',
  SavRemovedEvent = 'SavRemovedEvent',
  SavWorkStartDateAddedEvent = 'SavWorkStartDateAddedEvent',
  BuildingUpdatedEvent = 'BuildingUpdatedEvent',
  BuildingDeletedEvent = 'BuildingDeletedEvent',
  CustomerCreatedEvent = 'CustomerCreatedEvent',
  CustomerUpdatedEvent = 'CustomerUpdatedEvent',
  CustomerDisabledEvent = 'CustomerDisabledEvent',
  CustomerRestoredEvent = 'CustomerRestoredEvent',
  GcInvoicePdfUpdatedEvent = 'GcInvoicePdfUpdatedEvent',
  GcCreditNotePdfUpdatedEvent = 'GcCreditNotePdfUpdatedEvent',
  GcDepositPdfUpdatedEvent = 'GcDepositPdfUpdatedEvent',
  GcQuotePdfUpdatedEvent = 'GcQuotePdfUpdatedEvent'
}

export interface PagedResultDtoOfDisplayAllGcInvoicesdto {
  values?: DisplayAllGcInvoicesdto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayAllGcInvoicesdto {
  /** @format int32 */
  interventionId?: number;
  requestReferenceNumber?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  gcQuoteReference?: string | null;
  order?: DisplayDateWithMessageDto | null;
  reference?: string | null;
  isDeleted?: boolean;
  isLost?: boolean;
  quoteType?: QuoteType;
  customer?: string | null;
  buildingName?: string | null;
  agencyName?: string | null;
  /** @format int32 */
  agencyId?: number;
  buildingAddress?: DisplayAddressDto | null;
  gcInvoice?: DisplayGcInvoiceDto | null;
  agencyInvoice?: DisplayAgencyInvoiceDto | null;
  reminders?: DisplayReminderDto[] | null;
  interventionComments?: DisplayCommentDto[] | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  assignedSalesAgent?: DisplayUserProfileDto | null;
}

export interface DisplayGcInvoiceDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: DisplayAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format int32 */
  agencyInvoiceId?: number;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
  paymentComment?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  reduction?: DisplayReductionDto | null;
  deposits?: DisplayDepositDto[] | null;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  version?: number;
  pdf?: DisplayFileDto | null;
  /** @format date-time */
  validationDate?: string | null;
  state?: GcInvoiceState;
  customerPaymentInstallments?: DisplayCustomerPaymentInstallmentDto[] | null;
  comments?: DisplayCommentDto[] | null;
  /** @format decimal */
  totalHt?: number;
  /** @format decimal */
  taxAmount?: number;
  /** @format decimal */
  totalTtc?: number;
  /** @format decimal */
  depositTotalHt?: number;
  /** @format decimal */
  depositTotalTtc?: number;
  /** @format decimal */
  taxAmountLeftToPay?: number;
  /** @format decimal */
  leftToPayTtc?: number;
  /** @format decimal */
  remainingPaymentTtc?: number;
  agencyInvoicePaymentAuthorizations?:
    | DisplayInvoicePaymentAuthorizationDto[]
    | null;
  /** @format date-time */
  agencyInvoicePaymentDate?: string | null;
  reminders?: DisplayReminderDto[] | null;
  /** @format decimal */
  leftToPayHt?: number;
  reconciliation?: string | null /** @format int32 */;
  gcInvoiceId?: number;
  depositReference?: string;
}

export interface DisplayAgencyInvoiceDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string;
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  version?: number;
  isUnlocked?: boolean;
  /** @format date-time */
  validationDate?: string | null;
  gcInvoices?: DisplayGcInvoiceIdentityDto[] | null;
  pdf?: DisplayFileDto | null;
  invoicePaymentAuthorizations?: DisplayInvoicePaymentAuthorizationDto[] | null;
  /** @format date-time */
  paymentDate?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format decimal */
  total?: number;
  reduction?: DisplayReductionDto | null;
  refusalReason?: string | null;
  /** @format date-time */
  refusalDate?: string | null;
}

export interface DisplayGcInvoicesMangerStatsDto {
  /** @format decimal */
  totalUnpaidInvoices?: number;
  /** @format decimal */
  totalpaidInvoices?: number;
  /** @format decimal */
  monthlyTotalUnpaidInvoices?: number;
  /** @format decimal */
  monthlyTotalpaidInvoices?: number;
  /** @format decimal */
  totalDeposits?: number;
  /** @format int32 */
  allInvoicesKindCount?: number;
}

export interface DisplayInvoiceDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: DisplayReductionDto | null;
  quoteOperationsGroup?: DisplayOperationGroupDto[] | null;
  /** @format decimal */
  totalHtBeforeReduction?: number | null;
  /** @format decimal */
  totalHt?: number | null;
  /** @format decimal */
  taxAmount?: number | null;
  /** @format decimal */
  totalTtc?: number | null;
  /** @format decimal */
  laborTotal?: number | null;
  /** @format decimal */
  suppliesTotal?: number | null;
  /** @format decimal */
  depositTotalHt?: number | null;
  /** @format decimal */
  depositTotalTtc?: number | null;
  /** @format decimal */
  leftToPayHt?: number | null;
  /** @format decimal */
  taxAmountLeftToPay?: number | null;
  /** @format decimal */
  leftToPayTtc?: number | null;
  depositDetails?: DisplayDepositDetail[] | null;
}

export interface DisplayDepositDetail {
  reference?: string | null;
  /** @format decimal */
  totalHt?: number | null;
  state?: GcDepositState | null;
}

export interface CreateInvoiceDto {
  /** @format int32 */
  interventionId?: number;
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: CreateReductionDto | null;
  quoteOperationsGroup?: CreateOperationGroupDto[] | null;
  /** @format int32 */
  gcInvoiceId?: number | null;
}

export interface CreateInvoiceMarginRateDto {
  invoice?: CreateInvoiceDto;
  /** @format decimal */
  desiredTotal?: number;
}

export interface PagedResultDtoOfDisplayGcInvoiceLisitingDto {
  values?: DisplayGcInvoiceLisitingDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayGcInvoiceLisitingDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  dateFacture?: string | null;
  /** @format int32 */
  idClient?: number | null;
  client?: string | null;
  /** @format date-time */
  dateEcheance?: string | null;
  libelle?: string | null;
  /** @format decimal */
  montantHt?: number | null;
  /** @format decimal */
  montantRemiseTTC?: number | null;
  /** @format decimal */
  montantRemiseTva?: number | null;
  /** @format decimal */
  montantRemiseHt?: number | null;
  /** @format decimal */
  montantFactureTva?: number | null;
  /** @format decimal */
  montantTtc?: number | null;
  reference?: string | null;
  idStatut?: GcInvoiceEtatEnum;
  statut?: string | null;
  avoir?: boolean;
  /** @format int32 */
  annee?: number;
  /** @format int32 */
  numero?: number;
  search?: string | null;
}

export type FilterDto = Pageable & {
  /** @format date-time */
  dateDebut?: string | null;
  /** @format date-time */
  dateFin?: string | null;
  searchField?: string | null;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  filter?: FilterDescriptorDto[] | null;
};

export interface FilterDescriptorDto {
  logic?: string | null;
  member?: string | null;
  operator?: FilterOperatorDto;
  value?: any;
}

export enum FilterOperatorDto {
  IsLessThan = 'IsLessThan',
  IsLessThanOrEqualTo = 'IsLessThanOrEqualTo',
  IsEqualTo = 'IsEqualTo',
  IsNotEqualTo = 'IsNotEqualTo',
  IsGreaterThanOrEqualTo = 'IsGreaterThanOrEqualTo',
  IsGreaterThan = 'IsGreaterThan',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  Contains = 'Contains',
  IsContainedIn = 'IsContainedIn',
  DoesNotContain = 'DoesNotContain',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  IsNullOrEmpty = 'IsNullOrEmpty',
  IsNotNullOrEmpty = 'IsNotNullOrEmpty'
}

export interface Pageable {
  /** @format int32 */
  skip?: number;
  /** @format int32 */
  take?: number;
}

export interface CreateInvoiceReconciliationDto {
  invoicesIds?: number[] | null;
  /** @format date-time */
  date?: string | null;
  /** @format int32 */
  reconciliationTypeId?: number;
}

export interface PagedResultDtoOfDisplayAllGcQuotesDto {
  values?: DisplayAllGcQuotesDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayAllGcQuotesDto {
  order?: DisplayDateWithMessageDto | null;
  reference?: string | null;
  /** @format int32 */
  interventionId?: number;
  /** @format int32 */
  customerId?: number;
  requestReferenceNumber?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  isDeleted?: boolean;
  isLost?: boolean;
  quoteType?: QuoteType;
  customer?: string | null;
  buildingName?: string | null;
  buildingAddress?: DisplayAddressDto | null;
  workNature?: string | null;
  /** @format date-time */
  workStartDate?: string | null;
  gcQuote?: DisplayGcQuoteDto | null;
  agencyQuote?: DisplayAgencyQuoteDto | null;
  reminders?: DisplayReminderDto[] | null;
  interventionComments?: DisplayCommentDto[] | null;
  /** @format int32 */
  assignedSalesAgentId?: number | null;
  assignedSalesAgent?: DisplayUserProfileDto | null;
  subject?: string | null;
  message?: string | null;
  period?: DisplayDateRangeDto | null;
  agencyName?: string | null;
  /** @format int32 */
  agencyId?: number;
}

export interface DisplayAgencyQuoteDto {
  siteAddress?: DisplayAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: DisplayFileDto[] | null;
  quoteOperationsGroup?: DisplayQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  version?: number;
  isUnlocked?: boolean;
  reference?: string | null;
  quoteConditions?: DisplayQuoteConditionDto[] | null;
  /** @format date-time */
  validationDate?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  reduction?: DisplayReductionDto | null;
}

export interface DisplayQuotesManagerStatsDto {
  /** @format decimal */
  totalOrder?: number;
  /** @format decimal */
  totalWorkingHours?: number;
  /** @format decimal */
  totalWorkingHoursAcceptedNotPaidQuotes?: number;
  /** @format int32 */
  countAcceptedNotPaidQuotes?: number;
  /** @format decimal */
  totalInvoicedQuotes?: number;
  /** @format decimal */
  totalAcceptedNotPaidQuotes?: number;
}

export interface DisplayQuoteDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: DisplayReductionDto | null;
  quoteOperationsGroup?: DisplayOperationGroupDto[] | null;
  /** @format decimal */
  totalHtBeforeReduction?: number | null;
  /** @format decimal */
  totalHt?: number | null;
  /** @format decimal */
  taxAmount?: number | null;
  /** @format decimal */
  totalTtc?: number | null;
  /** @format decimal */
  laborTotal?: number | null;
  /** @format decimal */
  suppliesTotal?: number | null;
}

export interface CreateQuoteDto {
  /** @format float */
  taxRate?: number;
  /** @format float */
  marginRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: CreateReductionDto | null;
  quoteOperationsGroup?: CreateOperationGroupDto[] | null;
}

export interface CreateQuoteMarginRateDto {
  quote?: CreateQuoteDto;
  /** @format decimal */
  desiredTotal?: number;
}

export interface PagedResultDtoOfDisplayGcQuoteLisitingDto {
  values?: DisplayGcQuoteLisitingDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayGcQuoteLisitingDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  dateDevis?: string | null;
  /** @format int32 */
  idClient?: number | null;
  client?: string | null;
  libelle?: string | null;
  /** @format decimal */
  montantHt?: number | null;
  /** @format decimal */
  montantRemiseTTC?: number | null;
  /** @format decimal */
  montantRemiseTva?: number | null;
  /** @format decimal */
  montantRemiseHt?: number | null;
  /** @format decimal */
  montantDevisTva?: number | null;
  /** @format decimal */
  montantTtc?: number | null;
  reference?: string | null;
  idStatut?: GcQuoteEtatEnum;
  statut?: string | null;
  /** @format int32 */
  annee?: number;
  /** @format int32 */
  numero?: number;
  search?: string | null;
}

export enum GcQuoteEtatEnum {
  EnAttente = 'En_Attente',
  EnCommande = 'En_Commande',
  Imprime = 'Imprime',
  Facture = 'Facture',
  Annule = 'Annule'
}

export interface UpdateDepositDto {
  /** @format date-time */
  paymentDueDate?: string | null;
  comment?: string | null;
  internalMessage?: string | null;
  /** @format int32 */
  gcInvoiceId?: number | null;
  siteAddress?: UpdateAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: UpdateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number | null;
  warning?: string | null;
  /** @format float */
  taxRate?: number | null;
  /** @format float */
  timeSpent?: number | null;
  /** @format float */
  marginRate?: number | null;
  photos?: UpdateFileDto[] | null;
  quoteOperationsGroup?: UpdateQuoteOperationsGroupDto[] | null;
  quoteConditions?: UpdateQuoteConditionDto[] | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  depositEntry?: UpdateDepositEntryDto | null;
}

export interface UpdateFileDto {
  fileId?: string | null;
  fileName?: string | null;
}

export interface UpdateQuoteOperationsGroupDto {
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: UpdateQuoteOperationDto[] | null;
}

export interface UpdateQuoteOperationDto {
  description?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  unit?: Unit | null;
  photos?: UpdateFileDto[] | null;
  supplies?: UpdateQuoteOperationSupplyDto[] | null;
}

export interface UpdateQuoteOperationSupplyDto {
  type?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  unit?: Unit | null;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
}

export interface UpdateQuoteConditionDto {
  /** @format int32 */
  number?: number;
  title?: string | null;
  content?: string | null;
}

export interface UpdateDepositEntryDto {
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateDepositPaymentDto {
  /** @format date-time */
  paymentDate?: string | null;
  paymentComment?: string | null;
}

export interface CreateGcCreditNotePaymentDto {
  /** @format date-time */
  paymentDate?: string | null;
  paymentComment?: string | null;
}

export interface CreateAgencyDepositDto {
  reference?: string | null;
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: CreateFileDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  pdf?: CreateFileDto | null;
  depositEntry?: CreateDepositEntryDto | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format decimal */
  total?: number | null;
  /** @format date-time */
  creationDate?: string | null;
}

export interface CreateFileDto {
  fileId?: string | null;
  fileName?: string | null;
}

export interface CreateQuoteOperationsGroupDto {
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
  quoteOperations?: CreateQuoteOperationDto[] | null;
}

export interface CreateQuoteOperationDto {
  description?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  quantity?: number;
  unit?: Unit | null;
  photos?: CreateFileDto[] | null;
  supplies?: CreateQuoteOperationSupplyDto[] | null;
}

export interface CreateQuoteOperationSupplyDto {
  type?: string | null;
  /** @format decimal */
  purchasePrice?: number;
  unit?: Unit | null;
  /** @format float */
  quantity?: number;
  /** @format float */
  wastedQuantity?: number;
  /** @format float */
  franchiserMarginRate?: number;
  /** @format float */
  agencyMarginRate?: number;
  isMultipliable?: boolean;
}

export interface CreateDepositEntryDto {
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateUnlockMotiveDto {
  motive?: string | null;
}

export interface UpdateAgencyDepositDto {
  siteAddress?: UpdateAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: UpdateFileDto[] | null;
  quoteOperationsGroup?: UpdateQuoteOperationsGroupDto[] | null;
  pdf?: UpdateFileDto | null;
  depositEntry?: UpdateDepositEntryDto | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format decimal */
  total?: number | null;
  /** @format date-time */
  creationDate?: string | null;
}

export interface CreateInvoicePaymentAuthorizationDto {
  state?: InvoicePaymentAuthorizationState;
  motive?: string | null;
}

export interface CreateCustomerPaymentInstallmentDto {
  /** @format decimal */
  amount?: number;
  /** @format date-time */
  date?: string | null;
}

export interface PagedResultDtoOfDisplayReminderDto {
  values?: DisplayReminderDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface CreateGcInvoiceCommentDto {
  message?: string | null;
}

export interface CreateDepositCommentDto {
  message?: string | null;
}

export interface CreateCreditNoteCommentDto {
  message?: string | null;
}

export interface CreateGcDepositCustomerPaymentInstallmentDto {
  /** @format decimal */
  amount?: number;
  /** @format date-time */
  date?: string | null;
}

export interface CreateAgencyDepositPaymentAuthorizationDto {
  state?: InvoicePaymentAuthorizationState;
  motive?: string | null;
}

export interface UpdateCommentDto {
  message?: string | null;
}

export interface UpdateReminderDto {
  type?: ReminderType;
  isAgencyReminder?: boolean | null;
  mailSubject?: string | null;
  mailBody?: string | null;
  internalMessage?: string | null;
  /** @format date-time */
  completionDate?: string | null;
}

export interface CreateInterventionDto {
  quoteType?: QuoteType | null;
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  isUrgent?: boolean | null;
  internalMessage?: string | null;
  /** @format int32 */
  buildingId?: number | null;
  hasAerialBucket?: boolean;
  period?: CreateDateRangeDto | null;
  /** @format float */
  agencyAmount?: number;
  /** @format float */
  minorWorksBudget?: number;
  /** @format float */
  aerialBucketAmount?: number;
  /** @format int32 */
  agencyId?: number | null;
  hasPreventionPlan?: boolean;
  hasAmiante?: boolean;
  hasCsps?: boolean;
  /** @format int32 */
  assignedAgentId?: number | null;
  requestReferenceNumber?: string | null;
  isVisitReportRequired?: boolean;
  isPrematureInvoiceAllowed?: boolean;
  twimmId?: string | null;
}

export enum GcState {
  Validated = 'Validated',
  InProgress = 'InProgress',
  NotDone = 'NotDone'
}

export enum AgencyState {
  Done = 'Done',
  NotDone = 'NotDone'
}

export enum ReminderTypeFilter {
  InterventionQuote = 'InterventionQuote',
  InterventionVisitReport = 'InterventionVisitReport',
  InterventionReceptionRecord = 'InterventionReceptionRecord',
  InterventionPreventionPlanFile = 'InterventionPreventionPlanFile',
  InterventionSav = 'InterventionSav',
  InterventionInvoice = 'InterventionInvoice',
  InterventionDeposit = 'InterventionDeposit',
  ALL = 'ALL'
}

export enum TaskType {
  CreateContactInstance = 'CreateContactInstance',
  CreateAppointment = 'CreateAppointment',
  AddWorkStartDate = 'AddWorkStartDate',
  UpdateAgencyQuote = 'UpdateAgencyQuote',
  UpdateAgencyInvoice = 'UpdateAgencyInvoice',
  ALL = 'ALL',
  ATTRIBUTION = 'ATTRIBUTION',
  UpdateAgencyVisitReport = 'UpdateAgencyVisitReport'
}

export enum InterventionState2 {
  InProgress = 'InProgress',
  Invoiced = 'Invoiced',
  Deleted = 'Deleted',
  Draft = 'Draft'
}

export interface DisplaySingleInterventionDto {
  /** @format int32 */
  id?: number;
  quoteType?: QuoteType;
  /** @format date-time */
  requestDate?: string | null;
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  appointments?: DisplayInterventionEventDto[] | null;
  order?: DisplayDateWithMessageDto | null;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  /** @format int32 */
  assignedAgentId?: number | null;
  isUrgent?: boolean;
  internalMessage?: string | null;
  hasAerialBucket?: boolean;
  period?: DisplayDateRangeDto | null;
  /** @format float */
  agencyAmount?: number;
  /** @format float */
  minorWorksBudget?: number;
  /** @format float */
  aerialBucketAmount?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format date-time */
  workFinishDate?: string | null;
  comments?: DisplayCommentDto[] | null;
  reference?: string | null;
  requestReferenceNumber?: string | null;
  contactInstances?: DisplayInterventionEventDto[] | null;
  agency?: DisplayAgencyDto | null;
  /** @format date-time */
  agencyAssignmentDate?: string | null;
  building?: DisplayBuildingWithCustomerDto | null;
  receptionRecordFile?: DisplayFileDto | null;
  receptionRecordPredictions?: DisplayReceptionRecordPredictionsDto | null;
  preventionPlanFile?: DisplayFileDto | null;
  files?: DisplayFileDto[] | null;
  savs?: DisplaySavDto[] | null;
  hasPreventionPlan?: boolean;
  isDeleted?: boolean;
  hasAmiante?: boolean;
  hasCsps?: boolean;
  isVisitReportRequired?: boolean;
  isPrematureInvoiceAllowed?: boolean;
  /** @format int32 */
  parentInterventionId?: number | null;
  parentInterventionReference?: string | null;
  gcDeposits?: DisplayGcDepositIdentityDto[] | null;
  agencyDeposits?: DisplayDepositIdentity[] | null;
  reminders?: DisplayReminderDto[] | null;
  terminationMotive?: string | null;
  isLost?: boolean;
  agencyInvoices?: DisplayInvoiceIdentityDto[] | null;
  gcInvoices?: DisplayGcInvoiceIdentityDto[] | null;
  twimmId?: string | null;
}

export interface CreateTerminationMotiveDto {
  terminationMotive?: string | null;
}

export interface CreateInterventionEventDto {
  participants?: CreateEventParticipantDto[] | null;
  /** @format date-time */
  date?: string | null;
  subject?: string | null;
  internalMessage?: string | null;
}

export interface DisplayAgencyVisitReportDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  creationDate?: string | null;
  reference?: string | null;
  /** @format int32 */
  version?: number;
  description?: string | null;
  roofViews?: DisplayOrderablePhotoDto[] | null;
  satelliteViews?: DisplayOrderablePhotoDto[] | null;
  diagnostic?: DisplayDiagnosticLineDto[] | null;
  isUnlocked?: boolean;
  conclusion?: string | null;
  name?: string | null;
  /** @format date-time */
  validationDate?: string | null;
  gcVisitReport?: DisplayGcVisitReportIdentityDto | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
}

export interface DisplayOrderablePhotoDto {
  /** @format int32 */
  order?: number;
  photo?: DisplayFileDto | null;
}

export interface DisplayDiagnosticLineDto {
  /** @format int32 */
  id?: number;
  title?: string | null;
  problem?: string | null;
  solution?: string | null;
  problemPhotos?: DisplayOrderablePhotoDto[] | null;
  solutionPhotos?: DisplayOrderablePhotoDto[] | null;
}

export interface CreateAgencyVisitReportDto {
  description?: string | null;
  roofViews?: CreateOrderablePhotoDto[] | null;
  satelliteViews?: CreateOrderablePhotoDto[] | null;
  diagnostic?: CreateDiagnosticLineDto[] | null;
  conclusion?: string | null;
  name?: string | null;
  reference?: string | null;
  /** @format date-time */
  creationDate?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
}

export interface CreateOrderablePhotoDto {
  /** @format int32 */
  order?: number;
  photo?: CreateFileDto | null;
}

export interface CreateDiagnosticLineDto {
  title?: string | null;
  problem?: string | null;
  solution?: string | null;
  problemPhotos?: CreateOrderablePhotoDto[] | null;
  solutionPhotos?: CreateOrderablePhotoDto[] | null;
}

export interface DisplayGcVisitReportDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  creationDate?: string | null;
  reference?: string | null;
  /** @format int32 */
  version?: number;
  description?: string | null;
  roofViews?: DisplayOrderablePhotoDto[] | null;
  satelliteViews?: DisplayOrderablePhotoDto[] | null;
  diagnostic?: DisplayDiagnosticLineDto[] | null;
  conclusion?: string | null;
  contactInfo?: DisplayContactInfoDto | null;
  pdf?: DisplayFileDto | null;
  /** @format date-time */
  validationDate?: string | null;
  state?: GcVisitReportState;
  address?: DisplayAddressDto | null;
  name?: string | null;
  /** @format int32 */
  agencyVisitReportId?: number;
}

export interface CreateGcVisitReportDto {
  description?: string | null;
  roofViews?: CreateOrderablePhotoDto[] | null;
  satelliteViews?: CreateOrderablePhotoDto[] | null;
  diagnostic?: CreateDiagnosticLineDto[] | null;
  conclusion?: string | null;
  contactInfo?: CreateContactInfoDto | null;
  address?: CreateAddressDto | null;
  name?: string | null;
  /** @format int32 */
  agencyVisitReportId?: number;
}

export interface CreateContactInfoDto {
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
}

export interface UpdateAgencyVisitReportDto {
  description?: string | null;
  roofViews?: UpdateOrderablePhotoDto[] | null;
  satelliteViews?: UpdateOrderablePhotoDto[] | null;
  diagnostic?: UpdateDiagnosticLineDto[] | null;
  conclusion?: string | null;
  name?: string | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
}

export interface UpdateOrderablePhotoDto {
  /** @format int32 */
  order?: number;
  photo?: UpdateFileDto | null;
}

export interface UpdateDiagnosticLineDto {
  title?: string | null;
  problem?: string | null;
  solution?: string | null;
  problemPhotos?: UpdateOrderablePhotoDto[] | null;
  solutionPhotos?: UpdateOrderablePhotoDto[] | null;
}

export interface UpdateGcVisitReportDto {
  description?: string | null;
  roofViews?: UpdateOrderablePhotoDto[] | null;
  satelliteViews?: UpdateOrderablePhotoDto[] | null;
  diagnostic?: UpdateDiagnosticLineDto[] | null;
  conclusion?: string | null;
  contactInfo?: UpdateContactInfoDto | null;
  address?: UpdateAddressDto | null;
  name?: string | null;
}

export interface UpdateContactInfoDto {
  personalTitle?: PersonalTitle;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  mobile?: string | null;
  fax?: string | null;
  email?: string | null;
}

export interface CreateSavDto {
  /** @format date-time */
  declarationDate?: string | null;
}

export interface UpdateSavWorkStartDate {
  /** @format date-time */
  workStartDate?: string | null;
}

export interface UpdateWorkStartDateDto {
  /** @format date-time */
  workStartDate?: string | null;
}

export interface CreateAgencyQuoteDto {
  reference?: string | null;
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: CreateQuoteOperationPhotoDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  quoteConditions?: CreateQuoteConditionDto[] | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  reduction?: CreateReductionDto | null;
}

export interface CreateQuoteOperationPhotoDto {
  fileId?: string | null;
  fileName?: string | null;
}

export interface CreateQuoteConditionDto {
  /** @format int32 */
  number?: number;
  title?: string | null;
  content?: string | null;
}

export interface CreateGcQuoteDto {
  billingAddress?: CreateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format float */
  taxRate?: number;
  /** @format decimal */
  hourlyCost?: number;
  reduction?: CreateReductionDto | null;
  comment?: string | null;
  warning?: string | null;
  plannedDeposits?: CreatePlannedDepositDto[] | null;
  /** @format float */
  marginRate?: number;
  photos?: CreateQuoteOperationPhotoDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  quoteConditions?: CreateQuoteConditionDto[] | null;
  contactInfo?: CreateContactInfoDto | null;
}

export interface CreatePlannedDepositDto {
  /** @format float */
  value?: number;
  type?: DepositType | null;
  /** @format date-time */
  date?: string | null;
  comment?: string | null;
}

export interface CreateAgencyInvoiceDto {
  reference?: string | null;
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  photos?: CreateFileDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  pdf?: CreateFileDto | null;
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
  /** @format decimal */
  total?: number | null;
  reduction?: CreateReductionDto | null;
  /** @format date-time */
  creationDate?: string | null;
}

export interface CreateGcInvoiceDto {
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: CreateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  /** @format int32 */
  agencyInvoiceId?: number;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  reduction?: CreateReductionDto | null;
  photos?: CreateFileDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  /** @format int32 */
  paymentMethodId?: number;
}

export interface UpdateGcInvoiceDto {
  /** @format date-time */
  paymentDueDate?: string | null;
  siteAddress?: UpdateAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: UpdateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number | null;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  taxRate?: number | null;
  /** @format float */
  timeSpent?: number | null;
  /** @format float */
  marginRate?: number | null;
  reduction?: UpdateReductionDto | null;
  photos?: UpdateFileDto[] | null;
  quoteOperationsGroup?: UpdateQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  paymentMethodId?: number | null;
}

export interface UpdateReductionDto {
  /** @format decimal */
  value?: number;
  type?: ReductionType | null;
}

export interface CreateGcInvoicePaymentDto {
  /** @format date-time */
  paymentDate?: string | null;
  paymentComment?: string | null;
}

export interface PagedResultDtoOfDisplayInterventionTaskDto {
  values?: DisplayInterventionTaskDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface UpdateCompletionDateDto {
  /** @format date-time */
  completionDate?: string | null;
}

export interface UpdateCommentDto2 {
  comment?: string | null;
}

export interface CreateInterventionTaskDto {
  type?: InterventionTaskType;
  /** @format date-time */
  completionDate?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @format int32 */
  userProfileId?: number | null;
  comment?: string | null;
}

export interface UpdateWorkFinishDateDto {
  /** @format date-time */
  workFinishtDate?: string | null;
}

export interface CreateReminderDto {
  for?: ReminderKeys | null;
  channel?: ReminderType | null;
  /** @format date-time */
  completionDate?: string | null;
  mailSubject?: string | null;
  mailBody?: string | null;
  internalMessage?: string | null;
  isAgencyReminder?: boolean;
  /** @format int32 */
  targetEntityId?: number;
}

export interface CreateAgencyInvoiceValidationDto {
  /** @format date-time */
  paymentDueDate?: string | null;
}

export interface CreateComplementaryInterventionDto {
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  isUrgent?: boolean | null;
  internalMessage?: string | null;
  hasAerialBucket?: boolean;
  hasPreventionPlan?: boolean;
  hasAmiante?: boolean;
  hasCsps?: boolean;
  /** @format int32 */
  assignedAgentId?: number | null;
  requestReferenceNumber?: string | null;
  isVisitReportRequired?: boolean;
  isPrematureInvoiceAllowed?: boolean;
}

export interface UpdateInterventionDto {
  subject?: string | null;
  message?: string | null;
  /** @format int32 */
  customerContactInfoIdForAgency?: number;
  /** @format int32 */
  customerContactInfoIdForGc?: number;
  isUrgent?: boolean | null;
  internalMessage?: string | null;
  period?: UpdateDateRangeDto | null;
  /** @format float */
  agencyAmount?: number;
  /** @format float */
  minorWorksBudget?: number;
  /** @format float */
  aerialBucketAmount?: number;
  /** @format int32 */
  assignedAgentId?: number | null;
  requestReferenceNumber?: string | null;
  hasPreventionPlan?: boolean | null;
  isVisitReportRequired?: boolean | null;
  hasCsps?: boolean | null;
  hasAmiante?: boolean | null;
  hasAerialBucket?: boolean | null;
  quoteType?: QuoteType | null;
  isPrematureInvoiceAllowed?: boolean | null;
  twimmId?: string | null;
}

export interface CreateGcCreditNoteDto {
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  /** @format date-time */
  paymentDueDate?: string | null;
  billingAddress?: CreateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  photos?: CreateFileDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format int32 */
  gcInvoiceId?: number | null;
  /** @format int32 */
  gcDepositId?: number | null;
  reduction?: CreateReductionDto | null;
  creditNoteEntry?: CreateCreditNoteEntryDto | null;
}

export interface CreateCreditNoteEntryDto {
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface UpdateCreditNoteDto {
  /** @format date-time */
  paymentDueDate?: string | null;
  siteAddress?: UpdateAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: UpdateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number | null;
  comment?: string | null;
  warning?: string | null;
  /** @format float */
  taxRate?: number | null;
  /** @format float */
  timeSpent?: number | null;
  /** @format float */
  marginRate?: number | null;
  photos?: UpdateFileDto[] | null;
  quoteOperationsGroup?: UpdateQuoteOperationsGroupDto[] | null;
  /** @format int32 */
  paymentMethodId?: number | null;
  reduction?: UpdateReductionDto | null;
  creditNoteEntry?: UpdateCreditNoteEntryDto | null;
}

export interface UpdateCreditNoteEntryDto {
  /** @format decimal */
  amount?: number;
  description?: string | null;
  title?: string | null;
  /** @format int32 */
  accountingEntryId?: number;
}

export interface CreateDepositDto {
  /** @format date-time */
  paymentDueDate?: string | null;
  comment?: string | null;
  internalMessage?: string | null;
  siteAddress?: CreateAddressDto | null;
  siteAddressName?: string | null;
  billingAddress?: CreateAddressDto | null;
  billingAddressName?: string | null;
  billingAddressComment?: string | null;
  workNature?: string | null;
  worktype?: WorkType | null;
  /** @format decimal */
  hourlyCost?: number;
  warning?: string | null;
  /** @format float */
  timeSpent?: number;
  /** @format float */
  marginRate?: number;
  /** @format float */
  taxRate?: number;
  photos?: CreateFileDto[] | null;
  quoteOperationsGroup?: CreateQuoteOperationsGroupDto[] | null;
  quoteConditions?: CreateQuoteConditionDto[] | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format int32 */
  agencyDepositId?: number | null;
  depositEntry?: CreateDepositEntryDto | null;
}

export interface PagedResultDtoOfDisplayCustomerRentabiliteDto {
  values?: DisplayCustomerRentabiliteDto[] | null;
  /** @format int32 */
  count?: number;
}

export interface DisplayCustomerRentabiliteDto {
  fullName?: string | null;
  /** @format int32 */
  id?: number | null;
  facture?: DisplayFactureDto | null;
  devise?: DisplayDeviseDto | null;
  rentabilite?: DisplayRentabiliteDto | null;
  montantFactureNoPaye?: DisplayMontantFactureNoPayeDto | null;
  montantCommandeNoFacture?: DisplayMontantCommandeNoFactureDto | null;
  montantDevisPerduAnnule?: DisplayMontantDevisPerduAnnuleDto | null;
  montantDevisAttente?: DisplayMontantDevisAttenteDto | null;
  montantDeviseGagne?: DisplayMontantDeviseGagneDto | null;
}

export interface DisplayFactureDto {
  /** @format decimal */
  factureNm1?: number;
  /** @format decimal */
  factureNm2?: number;
  /** @format decimal */
  factureN?: number;
}

export interface DisplayDeviseDto {
  /** @format decimal */
  deviseNm1?: number;
  /** @format decimal */
  deviseNm2?: number;
  /** @format decimal */
  deviseN?: number;
}

export interface DisplayRentabiliteDto {
  /** @format decimal */
  rentabiliteN?: number;
  /** @format decimal */
  rentabiliteNm1?: number;
  /** @format decimal */
  rentabiliteNm2?: number;
}

export interface DisplayMontantFactureNoPayeDto {
  /** @format decimal */
  montantFactureNoPayeN?: number;
  /** @format decimal */
  montantFactureNoPayeNm1?: number;
  /** @format decimal */
  montantFactureNoPayeNm2?: number;
}

export interface DisplayMontantCommandeNoFactureDto {
  /** @format decimal */
  montantCommandeNoFactureN?: number;
  /** @format decimal */
  montantCommandeNoFactureNm1?: number;
  /** @format decimal */
  montantCommandeNoFactureNm2?: number;
}

export interface DisplayMontantDevisPerduAnnuleDto {
  /** @format decimal */
  montantDevisPerduAnnuleN?: number;
  /** @format decimal */
  montantDevisPerduAnnuleNm1?: number;
  /** @format decimal */
  montantDevisPerduAnnuleNm2?: number;
}

export interface DisplayMontantDevisAttenteDto {
  /** @format decimal */
  montantDevisAttenteN?: number;
  /** @format decimal */
  montantDevisAttenteNm1?: number;
  /** @format decimal */
  montantDevisAttenteNm2?: number;
}

export interface DisplayMontantDeviseGagneDto {
  /** @format decimal */
  montantDeviseGagneN?: number;
  /** @format decimal */
  montantDeviseGagneNm1?: number;
  /** @format decimal */
  montantDeviseGagneNm2?: number;
}

export enum CustomerCommentsType {
  Customer = 'Customer',
  Contract = 'Contract',
  BillingAddress = 'BillingAddress',
  Reminder = 'Reminder'
}
export interface CreateCustomerCommentsDto {
  message?: string | null;
  type?: CustomerCommentsType;
}

export interface UpdatedCustomerCommentDto {
  message?: string | null;
}
export interface DisplayCustomerCommentDto {
  /** @format int32 */
  id?: number;
  type?: CustomerCommentsType;
  message?: string | null;
  /** @format int32 */
  userId?: number;
  /** @format date-time */
  creationDate?: string;
}
export interface DisplayAgencyAgent {
  agencyAgentFirstName?: string | null;
  agencyAgentLastName?: string | null;
  agencyAgentPhoneNumber?: string | null;
}
